<template>
  <div class="album-template-zone album-menu-zone album-top-menu" :data-tid="album.tid">
    <div class="container scrollable">
      <div class="mb-2" v-if="menu === 'addPage'">
        <b-input-group prepend="添加页面到">
          <b-select v-model="position">
            <option value="before">当前页之前</option>
            <option value="after">当前页之后</option>
            <option value="begin">第一页</option>
            <option value="end">最后页</option>
          </b-select>
        </b-input-group>
      </div>
      <grid class="templates" cols="4" v-if="loading">
        <grid-item v-for="i in 4" :key="i">
          <ske type="square"/>
        </grid-item>
      </grid>
      <grid class="templates" cols="4" v-else>
        <grid-item :key="item.name" v-for="item in templates">
          <square :src="getUrl(item.name)" height="100%" mode="contain"
                  :selected="item.name === activeTemplate"
                  @click="select(item)"/>
          <div class="caption">
            <span v-if="item.icount">{{item.icount}}图</span>
            <span v-if="item.tcount">文本</span>
          </div>
        </grid-item>
      </grid>
    </div>
    <footer>
      <b-btn @click="$parent.menuBack()" block>返回</b-btn>
    </footer>
  </div>
</template>

<script>
import { get, set } from 'lodash'
import inherits from '@/mixins/inherits'

export default {
  name: 'albumTemplateZone',
  mixins: [inherits('album', 'activeIndex', 'albumPages', 'menu')],
  data() {
    return {
      position: '',
      templates: [],
      saving: false,
      loading: false
    }
  },
  async created() {
    if (this.menu === 'addPage') {
      this.position = 'after'
    }
    this.loading = true
    await this.updateTemplates()
    this.loading = false
    setTimeout(() => {
      this.scrollIntoView('.selected')
    })
  },
  computed: {
    activeTemplate() {
      if (this.menu === 'addPage') {
        return null
      }
      return get(this.albumPages, [this.activeIndex, 'name'])
    },
    textLayers() {
      const layers = this.albumPages[this.activeIndex]?.layers || []
      return layers.filter(l => l.type === 'text')
    },
    activeTextLayers() {
      return this.textLayers.filter(i => i.content)
    }
  },
  mounted() {
    setTimeout(() => {
      this.scrollIntoView('.selected')
    })
  },
  methods: {
    getUrl(name) {
      return 'https://canvas.xinshu.me/generate/' + this.album.innerStyle.id + '.' + name +
        '?size=240&format=jpeg&placeholder=1'
    },
    async select(item) {
      if (this.menu === 'addPage') {
        this.addingPage = true
        let newIndex = this.activeIndex
        if (newIndex === 'cover') {
          newIndex = -1
        }
        if (this.position === 'after') {
          newIndex++
        }
        if (this.position === 'begin') {
          newIndex = 0
        }
        if (this.position === 'end') {
          newIndex = this.albumPages.length
        }
        await this.$parent.addPage(newIndex, item.name)
        this.addingPage = false
      } else {
        await this.setTemplate(item)
      }
    },
    async setTemplate(item) {
      try {
        const name = item.name
        if (item.tcount < this.activeTextLayers.length) {
          const confirmed = await this.$dialog.confirm('切换模板后文字将会丢失，是否继续')
          if (!confirmed) {
            return
          }
        }
        this.saving = true
        set(this.albumPages, [this.activeIndex, 'name'], name)
        await this.$parent.sendOperation('change_name', {index: this.activeIndex, name})
      } finally {
        this.saving = false
      }
    },
    async updateTemplates() {
      this.templates = await this.$ajax.fetchAvailableTemplates({
        innerStyle: this.album.innerStyle.id,
        tid: this.album.tid
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.album-template-zone.album-menu-zone {
  .templates {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @include media-breakpoint-down(sm) {
      overflow-x: auto;
      overflow-y: hidden;
      flex-wrap: nowrap;
      max-height: none;
    }

    .square + .square {
      margin-top: 10px;
    }

    .grid-item {
      &.hover {
        z-index: 50;
        opacity: .6;
      }
    }
  }

  .bottom {
    display: block;
    position: relative;
  }

  .grid-item {
    position: relative;
    text-align: center;
    min-width: 80px;

    &.selected {
      img {
        border-color: $primary;
      }
    }
  }

  .caption {
    font-size: 12px;
    white-space: nowrap;
    padding-left: 1em;
    padding-right: 1em;

    span + span {
      &:before {
        content: '+ ';
      }
    }
  }
}

::v-deep {
  .square-image {
    border: 1px solid $hr-border-color;
  }
}
</style>
