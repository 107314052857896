<template>
  <div class="album-pick-zone album-menu-zone">
    <div class="container scrollable">
      <div class="caption mb-1" v-if="images.length"><b>点击下方图片添加</b></div>
      <div class="image-candidates">
        <div class="text-muted w-100 p-3" v-if="!images.length">
          <div>
            <fa icon="images" size="2x"/>
          </div>
          <div>没有可用图片</div>
          <b-link @click="$parent.showMenu('gallery')">前往图库</b-link>
        </div>
        <grid cols="4" v-else>
          <grid-item v-for="item in images" :key="item.pid">
            <square class="image"
                    :src="$img(item.url, 240)" border mode="contain" @click="upImage(item)"/>
          </grid-item>
        </grid>
      </div>
    </div>
    <footer>
      <b-btn block @click="$parent.menuBack">返回</b-btn>
    </footer>
  </div>
</template>

<script>
import inherits from '@/mixins/inherits'
export default {
  name: 'albumPickZone',
  mixins: [inherits('album', 'images', 'activeIndex', 'layers', 'menu')],
  data() {
    return {
      index: '',
      gridSize: ''
    }
  },
  mounted() {
    this.index = this.menu.split('#')[1]
  },
  watch: {
    activeIndex() {
      this.$parent.menuBack()
    }
  },
  methods: {
    upImage(image) {
      const index = this.index
      const pid = image.pid
      const layer = this.layers.filter(l => l.pageIndex === this.activeIndex).find(l => l.name === index)

      if (!layer) {
        console.error('layer', index, 'not found')
        return
      }

      if (!image) {
        console.error('image', pid, 'not found')
        return
      }

      layer.content = image.url
      layer.id = image.pid

      this.images = this.images.filter(i => i.pid !== pid)
      this.$parent.syncPage(layer.pageIndex)

      this.$parent.menuBack()
    }
  }
}
</script>

<style scoped lang="scss">
.image-candidates {
  background-color: #fff;
  text-align: center;
  max-height: 320px;

  @include media-breakpoint-down(sm) {
    overflow-y: hidden;
    overflow-x: auto;

    .grid {
      flex-wrap: nowrap;
    }

    .grid-item {
      min-width: 80px;
    }
  }
}
</style>
