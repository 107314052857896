export default function (field, defaultValue = '', scope = '') {
  return {
    data() {
      scope = scope || this.$route.path
      const key = [field, scope].filter(Boolean).join(':')
      let value = this.$ls.get(key)
      if (Array.isArray(defaultValue) && !Array.isArray(value)) {
        value = []
      }
      return {
        // eslint-disable-next-line new-cap,new-parens
        [field]: value
      }
    },
    watch: {
      [field]: {
        handler(val) {
          console.log(val)
          scope = scope || this.$route.path
          const key = [field, scope].filter(Boolean).join(':')
          if (val === null || val === undefined || val === '') {
            this.$ls.remove(key)
          } else {
            this.$ls.set(key, val)
          }
        },
        deep: true
      }
    }
  }
}
