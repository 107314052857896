<template>
  <div class="album-cover-zone album-top-menu album-menu-zone">
    <div class="container scrollable">
      <template v-if="$parent.menu === 'coverText'">
        <b-form-group label="照片书标题（最多 20 字）">
          <b-input :value="album.name" @change="saveTitle" maxlength="20"
                   placeholder="给您的作品起一个名字吧"/>
        </b-form-group>

        <b-form-group label="副标题 / 作者（最多 20 字）" v-if="hasSubtitle">
          <b-input :value="cover.subtitle" @change="saveSubtitle" maxlength="20"
                   placeholder="在这里输入副标题或作者"/>
        </b-form-group>

        <div class="mt-2 font-weight-bold" v-if="!hasTitle">
          <fa icon="info-circle"/>
          照片书标题仅显示在最后一页条码上方
        </div>
      </template>

      <template v-else-if="$parent.menu === 'coverImage'">
        <empty v-if="!hasPic" icon-size="2x" icon="ban">不支持修改图片</empty>

        <template v-else-if="pickingImage">
          <div class="gallery">
            <div class="text-center mb-1" v-if="gallery.length">
              <fa icon="images"/>
              点击选择图片
            </div>
            <grid cols="4">
              <template v-if="!gallery.length">
                <grid-item v-for="i in 8" :key="i">
                  <ske type="square"/>
                </grid-item>
              </template>
              <grid-item v-for="i in gallery" :key="i">
                <square :src="$img(i, 320)" border @click="selectPic(i)"
                        :selected="cover.pic === i"/>
              </grid-item>
            </grid>
            <div class="mt-2 text-center text-muted" v-if="gallery.length">
              以上图片来自
              <b-link href="https://unsplash.com" target="_blank">
                <img src="https://img.xinshu.me/upload/5acaf423b25f6ba9592be5d81a74f189"
                     alt="Unsplash" style="height: 1em; vertical-align: middle;">
              </b-link>
            </div>
          </div>
        </template>

        <grid class="covers" cols="4" v-else>
          <grid-item>
            <square @click="uploadPic" border>
              <fa icon="cloud-upload"/>
              上传图片
            </square>
          </grid-item>
          <grid-item>
            <square @click="showGallery" border>
              <fa icon="images"/>
              官方图片库
            </square>
          </grid-item>
          <grid-item>
            <square @click="adjustImage" border :disabled="!cover.pic">
              <fa icon="arrows"/>
              调整图片
            </square>
          </grid-item>
          <grid-item>
            <square @click="deletePic" border :disabled="!cover.pic">
              <fa icon="undo"/>
              恢复默认
            </square>
          </grid-item>
        </grid>
      </template>

      <template v-else-if="$parent.menu === 'coverStyle'">
        <div class="mb-2">
          <b>选择封面样式</b>
          <div class="float-right text-muted">
            <fa icon="image"/>
            带图标封面可更换图片
          </div>
        </div>
        <grid class="covers" cols="3">
          <template v-if="!covers.length">
            <grid-item v-for="i in 4" :key="i">
              <ske type="square"/>
            </grid-item>
          </template>
          <grid-item :key="'cover' + index" v-for="(item, index) in covers">
            <square :selected="cover.codeName === item.codeName"
                    :src="coverUrl(item)"
                    @click="selectCover(item)" border height="fit">
              <div class="style-tag" v-if="item.editable">
                <fa icon="image"/>
              </div>
            </square>
          </grid-item>
        </grid>
      </template>
    </div>

    <footer>
      <b-btn @click="back" block>返回</b-btn>
    </footer>

    <move-area v-model="adjustingImage" @save="savePosition" v-if="adjustingImage"/>
  </div>
</template>

<script>
import inherits from '@/mixins/inherits'
import Upload from '@/models/upload'

import MoveArea from '@/components/MoveArea'

export default {
  name: 'albumCoverZone',
  components: {MoveArea},
  mixins: [inherits('album')],
  data() {
    return {
      gallery: [],
      pickingImage: false,
      adjustingImage: null,
      deleting: false,
      storage: new Upload(),
      deleteConfirm: '',
      covers: [],
      coverParams: {},

      hasTitle: false,
      hasSubtitle: false,
      hasPic: false
    }
  },
  beforeDestroy() {
    this.storage.destroy()
  },
  async created() {
    if (this.$parent.menu === 'coverStyle') {
      if (this.album.tid === 'calbum') {
        const codeName = this.cover.codeName
        this.covers = [{codeName, editable: codeName !== 'national.001'}]
      } else if (this.album.tid === 'usho-album') {
        const codeName = this.cover.codeName
        this.covers = [{codeName, editable: true}]
      } else {
        const results = await this.$ajax.fetchCoverTemplates()
        this.covers = results
          .filter(i => i.type === this.album.tid)
          .filter(i => i.innerStyle ? i.innerStyle === this.album.innerStyle.id : true)
      }

      if (!this.covers.some(i => i.codeName === this.cover.codeName)) {
        this.covers.unshift({codeName: this.cover.codeName})
      }
    } else {
      await this.fetchCoverParams(this.cover.codeName)
    }
  },
  computed: {
    editable() {
      const t = this.covers.find(i => i.codeName === this.cover.codeName) || {}
      return t.editable
    },
    cover: {
      get() {
        return {
          title: this.album.name,
          ...this.album.cover
        }
      },
      set(val) {
        this.album.cover = val
      }
    },
    aid() {
      return this.album.aid
    }
  },
  methods: {
    async showGallery() {
      this.pickingImage = true
      if (!this.gallery.length) {
        this.gallery = await this.$ajax.fetchGallery()
      }
    },
    async fetchCoverParams() {
      const codeName = this.cover.codeName
      let params = this.coverParams[codeName]
      if (!params) {
        params = await this.$req.get('https://canvas.xinshu.me/config/' + codeName)
        this.coverParams[codeName] = params
        const layerNames = params.layers.map(l => [l.name, l.alias])
          .flat()
          .filter(Boolean)
          .map(i => i.toLowerCase())
        this.hasPic = layerNames.includes('pic')
        this.hasTitle = layerNames.includes('title')
        this.hasSubtitle = layerNames.includes('subtitle')
      }
    },
    selectPic(url) {
      this.pickingImage = false
      const cover = {...this.cover, pic: url, x: '50%', y: '50%'}
      return this.syncAlbum({cover})
    },
    adjustImage() {
      this.adjustingImage = {
        ...this.cover
      }
    },
    selectCover(item) {
      const cover = {...this.cover, codeName: item.codeName}
      this.cover = cover
      return this.syncAlbum({cover})
    },
    inputCover() {
      const codeName = prompt('请输入封面代号')
      if (!codeName) {
        return
      }
      const cover = {...this.cover, codeName}
      this.cover = cover
      return this.syncAlbum({cover})
    },
    savePosition({x, y}) {
      const cover = {...this.cover, x, y}
      this.adjustingImage = null
      return this.syncAlbum({cover})
    },
    async saveTitle(name) {
      if (!name) {
        this.$alert.error('作品名称不能为空哦')
        return
      }
      await this.syncAlbum({name})
      this.$alert.success('标题已保存')
    },
    async saveSubtitle(value) {
      const cover = {...this.cover, subtitle: value}
      await this.syncAlbum({cover})
      this.$alert.success('副标题已保存')
    },
    deletePic() {
      const cover = {
        codeName: this.cover.codeName,
        subtitle: this.cover.subtitle,
        pic: '',
        x: '50%',
        y: '50%'
      }
      return this.syncAlbum({cover})
    },
    async uploadPic() {
      const result = await this.storage.upload()
      return this.selectPic(result.url)
    },
    async syncAlbum(data) {
      try {
        this.saving = true
        if (data.cover) {
          data.cover.pic = data.cover.pic || ''
          data.cover.codeName = data.cover.codeName || data.cover.name
          delete data.cover.name
        }
        const result = await this.$req.put(
          '/jianxiang/api/1/albums/' + this.album.aid + '/',
          data
        )
        this.cover = result.cover
        this.album.name = result.name
        this.album.title = result.name
      } finally {
        this.saving = false
      }
    },

    coverUrl(cover) {
      cover.subtitle = cover.subtitle || ''
      return this.getCoverUrl({cover}, 320)
    },
    back() {
      if (this.pickingImage) {
        this.pickingImage = false
      } else {
        this.$parent.menuBack()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.album-cover-zone {
  .covers {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @include media-breakpoint-down(sm) {
      flex-wrap: nowrap;
      overflow: auto;
      .square {
        min-width: 80px;
      }
    }
  }

  .style-tag {
    position: absolute;
    top: 4px;
    left: 0;
    width: 2.5em;
    line-height: 1.8;
    border-radius: 0 100px 100px 0;
    z-index: 0;
    background-color: $success;
    color: #fff;
    text-align: center;
    font-size: 12px;
  }

  .bottom {
    display: block;
    position: relative;
  }
}
</style>
