<template>
  <div class="album-style-zone album-menu-zone">
    <header class="clearfix" v-if="groups.length > 1">
      <b-nav tabs fill>
        <b-nav-item v-for="item in groups" :key="item" @click="tab = item"
                    :active="tab === item">{{item}}
        </b-nav-item>
      </b-nav>
    </header>
    <div class="container scrollable">
      <grid cols="4" sm="3">
        <grid-item class="style" v-for="item in styles" :key="item.id">
          <square :src="item.pic" border :selected="item.innerStyle === style" @click="select(item)"
                  class="primary" :data-tag-sm="item.tag" mode="contain"/>
          <div class="mt-2 text-center small">{{item.title}}</div>
        </grid-item>
      </grid>
    </div>
    <footer>
      <div class="buttons">
        <b-btn block @click="$parent.menuBack()">返回</b-btn>
      </div>
    </footer>
  </div>
</template>

<script>
import { chain, get } from 'lodash'
import inherits from '@/mixins/inherits'

export default {
  name: 'albumStyleZone',
  mixins: [inherits(['album', 'layers'])],
  data() {
    return {
      tab: '',
      albumStyles: [],
      saving: false
    }
  },
  computed: {
    groups() {
      return chain(this.albumStyles).map(i => i.type).filter(Boolean).uniq().value()
    },
    style() {
      return get(this.album, 'innerStyle.id')
    },
    styles() {
      return this.albumStyles.filter(i => i.type === this.tab)
    }
  },
  async created() {
    const results = await this.$ajax.fetchAlbumTemplates()
    this.albumStyles = results.filter(i => i.tid === this.album.tid).map(i => {
      i.type = i.type || ''
      return i
    })
    this.tab = chain(this.albumStyles).find({innerStyle: this.style}).get('type').value() ||
      get(this.groups, 0) || ''
  },
  methods: {
    async select(item) {
      const isSpecial = ['picturebook', 'frame-moment'].includes(this.album.tid)
      if (!isSpecial) {
        if (this.layers.filter(l => l.type === 'text' && l.content).length) {
          const confirmed = await this.$dialog.confirm({
            title: '切换模板',
            content: '您已经在页面中撰写了文本，切换模板会导致文本丢失，是否确认切换'
          })
          if (!confirmed) {
            return
          }
        }
        if (item.cover && item.cover !== this.album.cover.codeName) {
          let confirmed = !!item.forceCover
          if (!item.forceCover) {
            confirmed = await this.$dialog.confirm({
              content: '是否要同时替换封面为配套版本',
              okTitle: '替换',
              cancelTitle: '保持当前封面'
            })
            if (confirmed === null) {
              return
            }
          }
          if (confirmed) {
            await this.saveCover(item.cover)
          }
        }
      }
      await this.saveStyle(item.innerStyle)
    },
    async saveCover(codeName) {
      try {
        const cover = this.album.cover
        cover.codeName = codeName
        this.saving = true
        await this.$req.put('/jianxiang/api/1/albums/' + this.album.aid + '/', {cover})
        this.$forceUpdate()
      } finally {
        this.saving = false
      }
    },
    async saveStyle(id) {
      try {
        this.saving = true
        this.album.innerStyle.id = id
        await this.$parent.sendOperation('change_inner_style', {innerStyleId: id})
        this.$alert.success('已更换风格，请稍等页面更新')
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.album-style-zone {
  .styles {
    overflow: auto;
    @include media-breakpoint-down(sm) {
      flex-wrap: nowrap;
    }
  }
}
</style>
