<template>
  <div class="album-text-zone album-menu-zone album-top-menu">
    <div class="container scrollable">
      <div class="body">
        <b-form-group label="标题" v-if="titleLayer">
          <textarea class="form-control"
                    rows="1"
                    :maxlength="titleLayer.maxLength"
                    :placeholder="titleLayer.placeholder"
                    v-model="title"/>
          <div class="text-rows" :class="{'exceeded': titleLayer.maxLength}">
            {{titleLayer.rows}} / {{titleLayer.maxRows}}行
          </div>
        </b-form-group>
        <b-form-group label="正文">
          <textarea class="form-control"
                    rows="5" v-autosize
                    :maxlength="contentLayer.maxLength"
                    :placeholder="contentLayer.placeholder"
                    v-model="content"></textarea>
          <div class="text-rows" :class="{'exceeded': contentLayer.maxLength}">
            {{contentLayer.rows}} / {{contentLayer.maxRows}}行
          </div>
        </b-form-group>
      </div>
    </div>
    <footer>
      <div class="buttons">
        <b-btn @click="$parent.menuBack()" block>
          返回
        </b-btn>
        <b-btn @click="save" variant="primary" block :disabled="saving">
          <fa icon="spinner" spin v-if="saving"/>
          保存
        </b-btn>
      </div>
    </footer>
  </div>
</template>

<script>
import getLines from '@/utils/get-lines'
import inherits from '@/mixins/inherits'
import { chain } from 'lodash'

export default {
  name: 'albumTextZone',
  mixins: [inherits(['album', 'activeIndex', 'albumPages', 'activeText', 'zooming'])],
  data() {
    return {
      templates: {},
      hasTitle: false,
      title: '',
      content: '',
      defaultText: '',
      saving: false
    }
  },
  computed: {
    texts() {
      return chain(this.albumPages).get([this.activeIndex, 'layers'])
        .filter(i => i.type === 'text').value()
    },
    titleLayer() {
      if (this.texts.length <= 1) {
        return null
      }
      const layer = this.texts[0]
      const {rows} = getLines(this.title, layer)
      return {
        placeholder: '在此处撰写标题',
        rows,
        maxRows: layer.rows,
        maxLength: rows > layer.rows ? this.title.length : null,
        raw: layer
      }
    },
    contentLayer() {
      const layer = this.texts[this.texts.length - 1]
      const {rows} = getLines(this.content, layer)
      return {
        placeholder: this.defaultText || '在这里撰写文字，不写则使用默认文本，输入空格使区域留白',
        rows,
        maxRows: layer.rows,
        maxLength: rows > layer.rows ? this.content.length : null,
        raw: layer
      }
    }
  },
  watch: {
    rows(val) {
      if (val > this.rows) {
        this.maxLength = this.content.length
      } else {
        this.maxLength = null
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const innerStyle = this.album.innerStyle.id
      const style = this.albumPages[this.activeIndex].name
      const templateName = innerStyle + '.' + style
      let template = this.templates[templateName]
      if (!template) {
        template = await this.$req.get('https://canvas.xinshu.me/config/' + templateName)
        this.templates[templateName] = template
      }
      const {layers} = template
      const layer = layers.find(i => i.name === 'text')
      if (layer && !layer.blank) {
        this.defaultText = layer.content
      }
      if (this.titleLayer) {
        this.title = this.titleLayer.raw.content
      }
      if (this.contentLayer) {
        this.content = this.contentLayer.raw.content
      }
    },
    async save() {
      try {
        if (this.titleLayer?.maxLength) {
          this.$alert.error('标题已超出最大行数，请删除部分文字后再保存')
          return
        }
        if (this.contentLayer?.maxLength) {
          this.$alert.error('正文已超出最大行数，请删除部分文字后再保存')
          return
        }
        if (this.titleLayer) {
          this.titleLayer.raw.content = this.title
        }
        this.contentLayer.raw.content = this.content
        this.saving = true
        await this.$parent.syncPage(this.activeIndex)
        this.$parent.menuBack()
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.album-text-zone {
  textarea {
    resize: none;
    word-break: break-all;
  }

  .text-rows {
    margin-top: -2.5em;
    text-align: right;
    padding: .5em;
    pointer-events: none;
    font-size: 12px;
    color: $text-muted;
    &.exceeded {
      color: $primary;
    }
  }
}
</style>
