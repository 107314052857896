<template>
  <div class="album-navigation" :data-tid="album.tid">
    <draggable animation="100" :disabled="!movingPage"
               @update="onSwapPage" handle=".page.active"
               draggable=".page.content" filter=".page.cover"
               class="page-list" :class="{moving: movingPage}">
      <div class="page cover" @click="activeIndex = 'cover'" v-if="hasCover"
           :class="{active: activeIndex === 'cover'}">
        <div class="page-content">
          <cover plain :book="album"/>
        </div>
        <div class="caption">封面</div>
      </div>
      <div class="page loading" v-if="loading && !pages.length">
        <div class="small text-center">加载中...</div>
      </div>
      <div class="page content" :data-index="i"
           @click="activeIndex = i" :class="[{active: activeIndex === i}, p.className]"
           v-for="(p, i) in pages" :key="prefix + 'page' + i">
        <div class="page-content" v-html="p.html"></div>
        <div class="caption">{{getCaption(i)}}</div>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import inherits from '@/mixins/inherits'

export default {
  name: 'albumNavigation',
  mixins: [
    inherits('album', 'albumPages', 'activeIndex', 'movingPage', 'hasCover')
  ],
  components: {draggable},
  data() {
    return {
      prefix: Date.now().toString(),
      pages: [],
      loading: false
    }
  },
  created() {
    this.initPages()
  },
  events: {
    scrollToActive() {
      this.$el.querySelector('.page.active')?.scrollIntoViewIfNeeded?.()
    }
  },
  watch: {
    albumPages: {
      handler() {
        this.initPages()
      },
      deep: true
    },
    activeIndex: {
      handler() {
        setTimeout(() => {
          const pageEl = this.$el.querySelector('.page.active')
          if (pageEl) {
            pageEl.scrollIntoViewIfNeeded()
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    async onSwapPage({oldDraggableIndex: oldIndex, newDraggableIndex: newIndex}) {
      const arr = this.albumPages
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
      this.activeIndex = newIndex
      await this.$parent.swapPage({oldIndex, newIndex})
    },
    async initPages() {
      console.log('initPages')
      this.loading = true
      this.pages = await Promise.all(this.albumPages.map(async p => {
        const html = await this.$ajax.fetchPage(p, this.album.innerStyle.id, 160)
        const div = document.createElement('div')
        div.innerHTML = html
        const contentEl = div.querySelector('[name="page-content"]')
        const width = contentEl.getAttribute('width')
        const height = contentEl.getAttribute('height')
        return {
          html,
          className: width > height ? 'h' : 'v'
        }
      }))
      this.prefix = Date.now().toString()
      this.loading = false
    },
    navigate(i) {
      this.activeIndex = i
    },
    getCaption(i) {
      if (/calendar/.test(this.album.tid)) {
        return i ? `${i}月` : '封面'
      }
      if (/note/.test(this.album.tid)) {
        return ['封面1', '封底1', '封面2', '封底2'][i]
      }
      return `第${i + 1}页`
    }
  }
}
</script>

<style scoped lang="scss">
.album-navigation {
  overflow: hidden;
  background-color: #fff;
  border-top: 1px solid $hr-border-color;

  @include media-breakpoint-up(sm) {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-right: 1px solid $hr-border-color;
    border-top: 0;
  }

  &[data-tid="xalbum"] {
    .page {
      min-width: 96px;
    }

    .page.cover .page-content {
      width: 50%;
    }
  }

  &[data-tid="album"] {
    .page.cover {
      padding-left: 17px;
      padding-right: 17px;
      @include media-breakpoint-up(sm) {
        padding-left: 25%;
        padding-right: 25%;
      }
    }
  }

  &[data-tid="picturebook"] {
    .page.cover {
      padding-left: 17px;
      padding-right: 17px;
      @include media-breakpoint-up(sm) {
        padding-left: 25%;
        padding-right: 25%;
      }
    }
  }

  &[data-tid="b5album"] {
    .page.cover {
      min-width: 120px;
    }
  }

  &[data-tid="postcard"] {
    .page.content.v {
      .page-content {
        width: 70.5%;
      }
    }
  }

  .page-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;
    padding: 4px;

    @include media-breakpoint-up(sm) {
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &.moving .page.active {
      .page-content {
        position: relative;

        &:after {
          z-index: 2;
          content: '按住拖动';
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #fff;
          line-height: 1.2;
          font-size: 12px;
          background-color: rgba(0, 0, 0, .3);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  .page {
    min-width: 80px;
    padding: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: end;

    &.loading {
      justify-content: center;
    }

    .page-content {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      min-width: 64px;
    }

    &.active .page-content {
      border-color: $primary;
      border-width: 2px;
    }
  }

  .page-content {
    margin-left: auto;
    margin-right: auto;
    border: 2px solid $hr-border-color;

    ::v-deep > div {
      pointer-events: none;
    }
  }

  .caption {
    text-align: center;
    font-size: 12px;
  }
}
</style>
