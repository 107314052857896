<template>
  <div class="album-share-menu">
    <template v-if="shareToType">
      <div class="inner">
        <div class="content">
          <template v-if="isWechat">
            <p>
              <img alt="朋友圈" class="icon" src="@/assets/images/icons/moments.png" v-if="shareToType === 'moments'">
              <img alt="微信好友" class="icon" src="@/assets/images/icons/group-message.svg" v-else>
            </p>
            <div>
              点击右上角
              <b>
                <fa fw icon="ellipsis-h" />
              </b>
              进行分享
            </div>
          </template>
          <template v-else>
            <div>
              <qrcode :text="`https://weixinshu.com/albums/${album.aid}?mode=s`" size="160px"></qrcode>
            </div>
            <div>
              <fa icon="weixin" fab/>
              微信扫码后
              <br>
              点击右上角
              <b>
                <fa fw icon="ellipsis-h"/>
              </b>
              进行分享
            </div>
          </template>
          <div>
            让好友一起来欣赏你的作品吧！
          </div>
        </div>
      </div>
      <footer>
        <div class="content">
          <b-btn @click="close" block variant="link">好的</b-btn>
        </div>
      </footer>
    </template>
    <template v-else>
      <div class="inner">
        <div class="content">
          <p>分享至</p>
          <b-row>
            <b-col>
              <div class="cursor-pointer" @click="shareTo('group-message')">
                <div class="mb-2">
                  <img alt="微信好友" class="icon" src="@/assets/images/icons/group-message.svg">
                </div>
                <span>微信好友</span>
              </div>
            </b-col>
            <b-col>
              <div @click="shareTo('moments')">
                <div class="mb-2">
                  <img alt="朋友圈" class="icon" src="@/assets/images/icons/moments.png">
                </div>
                <span>朋友圈</span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <footer>
        <div class="content">
          <b-btn @click="$emit('close')" block variant="link">关闭</b-btn>
        </div>
      </footer>
    </template>

    <div @click="$emit('close')" class="dimmer"></div>
  </div>
</template>

<script>
import Qrcode from '@/components/QRCode.vue'
import inherits from '@/mixins/inherits'

export default {
  name: 'albumMenu',
  mixins: [inherits('album')],
  components: {Qrcode},
  data() {
    return {
      shareToType: this.target || ''
    }
  },
  created() {
    this.onKeyUp = (e) => {
      if (e.keyCode === 27) {
        this.close()
      }
    }
    document.addEventListener('keyup', this.onKeyUp, false)
    if (this.isMiniProgram) {
      this.shareToType = 'friends'
    }
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    shareTo(type) {
      this.shareToType = type
    },
    close() {
      if (this.isMiniProgram) {
        return this.$emit('close')
      }
      this.shareToType = ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .album-share-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 21;
    background-color: #fff;
    text-align: center;
    border-top: 1px solid $hr-border-color;

    .content {
      max-width: 560px;
      margin-left: auto;
      margin-right: auto;
    }

    .icon {
      width: 2rem;
    }

    .inner {
      padding: 1rem;
    }

    .inner, footer {
      position: relative;
      z-index: 10;
      background-color: #fff;
      overflow: hidden;
    }

    footer {
      border-top: 1px solid $hr-border-color;
      padding: .25rem 0;

      @include iPhoneX {
        padding-bottom: $safe-area-bottom;
      }
    }

    .dimmer {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .2);
      z-index: 5;
    }
  }
</style>
