<template>
  <div class="album-gallery-zone album-menu-zone album-top-menu album-full-menu">
    <div class="container scrollable">
      <div class="images">
        <grid cols="4">
          <template v-if="loading">
            <grid-item v-for="i in 6" :key="i">
              <ske type="square"/>
            </grid-item>
          </template>

          <grid-item v-else-if="storage.uploading">
            <square :src="storage.uploading.thumbnail" border>
              <div class="mask">
                <template v-if="storage.progress">{{storage.progress}}%</template>
                <fa icon="spinner" spin v-else/>
              </div>
            </square>
          </grid-item>

          <div class="w-100" v-else-if="!albumImages.length">
            <empty icon="images">还没有图片哦</empty>
          </div>

          <grid-item :key="item.key" v-for="item in storage.waiting">
            <square :src="item.thumbnail" border>
              <div class="mask">等待中</div>
            </square>
          </grid-item>

          <grid-item v-for="item in albumImages" :key="item.image.pid"
                     @click.native="toggleItem(item)">
            <square mode="fit" border
                    :src="item.image.url + '!240'"
                    :selected="selected.includes(item)">
              <span class="used" v-if="item.used">已使用</span>
            </square>
          </grid-item>
        </grid>
      </div>
    </div>
    <transition name="fade">
      <footer>
        <div class="text-center mb-2">
          <template v-if="loading">
            <ske width="10em"/>
          </template>
          <template v-else-if="selected.length">
            <fa icon="check"/>
            已选 {{selected.length}} 张图片
          </template>
          <template v-else-if="albumImages.length">
            <fa icon="image"/>
            共 {{albumImages.totalCount}} 张图片，点击图片选择后可删除
          </template>
        </div>
        <div class="buttons" v-if="!selected.length">
          <b-btn block @click="$parent.menuBack">返回</b-btn>
          <b-btn block variant="success" class="px-1" v-if="!isAgentUser"
                 @click="$parent.showMenu('import')">
            从其他作品导入
          </b-btn>
          <b-btn variant="primary" block disabled v-if="loading">...</b-btn>
          <b-btn v-else block variant="primary"
                 :disabled="!!storage.uploading" @click="uploadImages">
            上传图片
          </b-btn>
        </div>
        <div class="buttons" v-else>
          <b-btn block @click="selected = []">取消选择</b-btn>
          <b-btn class="text-nowrap" block variant="primary" @click="deleteImages">
            <fa icon="trash"/>
            删除已选
          </b-btn>
        </div>
      </footer>
    </transition>
  </div>
</template>

<script>
import Upload from '@/models/upload'
import inherits from '@/mixins/inherits'

export default {
  name: 'albumGalleryZone',
  mixins: [
    inherits(['album', 'albumPages', 'images', 'prevMenus'])
  ],
  data() {
    return {
      albumImages: [],
      loading: false,
      deleting: false,
      submitting: false,
      storage: new Upload(),
      selected: []
    }
  },
  beforeDestroy() {
    document.body.classList.remove('modal-open')
  },
  async created() {
    document.body.classList.add('modal-open')
    if (!this.isAgentUser) {
      try {
        this.loading = true
        this.albumImages = await this.$ajax.fetchAlbumImages(this.$route.params)
        this.albumImages = this.albumImages.sort(a => a.used ? 1 : -1)
      } finally {
        this.loading = false
      }
    }
  },
  methods: {
    uploadImages() {
      const url = `/jianxiang/api/1/albums/${this.album.aid}/activities/`
      return this.storage.upload(100, async result => {
        const results = await this.$req.post(url, {images: [result]})
        const images = results.map(image => {
          return {
            aid: this.album.aid,
            used: false,
            image
          }
        })
        this.images.unshift(...results)
        this.albumImages.unshift(...images)
        this.album.uploadedImagesCount += images.length
      })
    },
    toggleItem(item) {
      if (this.selected.includes(item)) {
        this.selected = this.selected.filter(i => i !== item)
      } else {
        this.selected.push(item)
      }
    },
    async deleteImages() {
      const confirmed = await this.$dialog.confirm({
        title: '删除图片',
        content: '是否要删除已选 ' + this.selected.length + ' 图片'
      })
      if (!confirmed) {
        return
      }
      try {
        this.deleting = true
        const pids = this.selected.map(i => i.image.pid)
        const url = '/jianxiang/api/1/albums/' + this.album.aid + '/images/?pids=' + pids.join(',')
        await this.$req.delete(url)
        this.$alert.success('已删除 ' + this.selected.length + ' 图片')
        this.album.uploadedImagesCount -= this.selected.length
        for (const item of this.selected) {
          const pid = item?.image?.pid
          this.images.splice(this.images.findIndex(i => i.pid === pid), 1)
          this.albumImages.splice(this.albumImages.findIndex(i => i.image.pid === pid), 1)
        }
        if (this.selected.some(i => i.used)) {
          this.$parent.updateAlbumPages()
        }
        this.selected = []
      } finally {
        this.deleting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.album-gallery-zone {
  .empty-sign {
    cursor: pointer;
  }

  .used {
    position: absolute;
    top: 6px;
    left: 0;
    padding: 4px;
    line-height: 1;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    background-color: $success;
    color: #fff;
    font-size: $small-font-size;
    border: 1px solid $hr-border-color;
    border-left: 0;
    opacity: .9;
  }

  .add-image {
    background-color: $primary;
    color: #fff;
  }

  .pick-image {
    background-color: $success;
    color: #fff;
  }
}
</style>
