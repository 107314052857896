<template>
  <div class="album-shuffle-zone album-menu-zone album-top-menu">
    <template v-if="recovering">
      <div class="container text-left scrollable">
        <div class="list w-100">
          <div class="mb-2">以下是近10次自动排版记录</div>
          <b-card v-for="item in history" :key="item.date">
            排版于
            <datetime :value="item.date"/>
            / 原排版页数 {{item.count}}
            <b-link class="float-right" @click="recover(item)">恢复</b-link>
          </b-card>
        </div>
      </div>

      <footer>
        <b-row>
          <b-col>
            <b-btn block @click="recovering = false">返回</b-btn>
          </b-col>
        </b-row>
      </footer>
    </template>

    <template v-else-if="done">
      <div class="container scrollable">
        <div class="mb-2">
          <fa icon="check-circle" class="text-success align-middle" size="4x"/>
        </div>
        <h4 class="mb-2">排版完成</h4>
        <div>
          已为您排版 {{albumPages.length}} 页，包含图片 {{picLayers.length}} 张
          <template v-if="extraPicsCount">
            <br>
            多余 {{extraPicsCount}} 图片将放入缓存区。
          </template>
          <br>
          请预览后决定是否需要保留当前排版
        </div>
      </div>

      <footer>
        <div class="buttons">
          <b-btn block @click="$parent.menuBack()">
            <fa icon="eye"/>
            预览
          </b-btn>
          <b-btn block @click="resetPages" :disabled="saving">恢复</b-btn>
          <b-btn block variant="primary" @click="save" :disabled="saving">
            <fa icon="spinner" spin v-if="saving"/>
            保存
          </b-btn>
        </div>
      </footer>
    </template>

    <template v-else>
      <div class="container scrollable">
        <div class="mx-auto mb-3" style="width: 103px;">
          <square height="76.8%" v-show="submitting"
                  src="https://img.xinshu.me/upload/9a62d6f8ea4f477f922db9a4a2dd9dd3"/>
          <square height="76.8%" v-show="!submitting"
                  src="https://img.xinshu.me/upload/cb336df643cf48cf839e822dfcc38091"/>
        </div>
        <div>
          自动排版会使用您作品中所有图片（包括缓存区）
          <br>
          排版后可先预览效果，再决定是否需要保留排版结果
        </div>

        <div class="mt-3">
          <b-link variant="link" @click="clear" v-if="recovering">
            清理历史记录
          </b-link>
          <b-btn variant="link" @click="recovering = true" :disabled="!history.length" v-else>
            <fa icon="history"/>
            查看近期排版历史 ({{history.length}})
          </b-btn>
        </div>
      </div>

      <footer>
        <div class="buttons">
          <b-btn block @click="$parent.menuBack()" type="reset">返回</b-btn>
          <b-btn block variant="primary" disabled v-if="!imageCount">
            请至少上传或导入一张图片
          </b-btn>
          <b-btn variant="primary" block @click="onSubmit" :disabled="submitting" v-else>
            <fa icon="spinner" spin v-if="submitting"/>
            开始自动排版
          </b-btn>
        </div>
      </footer>
    </template>
  </div>
</template>

<script>
import { cloneDeep, pick } from 'lodash'

import inherits from '@/mixins/inherits'
import { wait } from '@/utils/wait-for'
import cached from '@/mixins/cached'

export default {
  name: 'albumShuffleZone',
  mixins: [
    inherits(['album', 'tmpPages', 'albumPages', 'layers', 'imageCount']),
    cached('history', [])
  ],
  data() {
    return {
      recovering: false,
      submitting: false,
      saving: false
    }
  },
  computed: {
    picLayers() {
      return this.layers.filter(i => i.type === 'image').filter(i => i.content)
    },
    textLayers() {
      return this.layers.filter(i => i.type === 'text').filter(i => i.content)
    },
    extraPicsCount() {
      return Math.max(0, this.imageCount - this.picLayers.length) || 0
    },
    done() {
      return this.tmpPages.length && !this.submitting
    }
  },
  created() {
    this.$ls.set('viewed.shuffleZone', 1)
  },
  methods: {
    async onSubmit() {
      if (this.textLayers.length) {
        const confirmed = await this.$dialog.confirm({
          content: '检测到您作品中有文字，自动排版不会排版文字，您可先预览排版结果再决定是否保存'
        })

        if (!confirmed) {
          return
        }
      }

      try {
        this.submitting = true
        const url = `/jianxiang/api/1/albums/${this.$route.params.albumId}/auto_typeset/`
        const {pages} = await this.$req.post(url)
        await wait(3000)
        this.tmpPages = cloneDeep(this.albumPages)
        this.albumPages = this.$ajax.extendAlbumPages(pages)
        await wait(500)
      } finally {
        this.submitting = false
      }
    },
    recover(item) {
      this.tmpPages = cloneDeep(this.albumPages)
      this.albumPages = this.$ajax.extendAlbumPages(item.pages)
      this.recovering = false
    },
    async clear() {
      if (!await this.$dialog.confirm('是否要清理历史记录')) {
        return
      }
      this.history = []
      this.recovering = false
    },
    async save() {
      const pages = cloneDeep(this.albumPages).map(page => {
        page.layers = page.layers.map(layer => {
          layer = pick(layer, ['id', 'content', 'x', 'y', 'type', 'text', 'scale', 'rotate'])
          if (layer.type === 'image') {
            if (layer.content) {
              layer.content = layer.content.split('!')[0]
            } else {
              // 清空图层注释及 URL
              layer.content = null
              layer.text = ''
            }
          } else {
            delete layer.x
            delete layer.y
            delete layer.text
          }
          return layer
        })
        delete page.pageIndex
        return page
      })
      try {
        this.saving = true
        await this.$req.put('/jianxiang/api/1/albums/' + this.album.aid + '/pages/', pages)
        this.history.unshift({
          date: new Date().toJSON(),
          count: this.tmpPages.length,
          pages: this.tmpPages
        })
        this.history = this.history.slice(0, 10)
        this.tmpPages = []
        this.$alert.success('排版结果已保存')
        this.$parent.updateImages()
      } finally {
        this.saving = false
      }
    },
    resetPages() {
      this.albumPages = this.tmpPages
      this.tmpPages = []
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
