<template>
  <div class="album-pick-zone album-menu-zone album-top-menu album-full-menu">
    <div class="container scrollable">
      <div class="body">
        <template v-if="loading === 1">
          <p class="placeholder" v-for="i in 8" :key="'p' + i"></p>
        </template>

        <empty v-else-if="!albums.length" icon="image">没有可导入的作品哦</empty>

        <template v-else>
          <div class="group" v-for="album in albums" :key="album.aid"
               :class="{active: active === album}" :data-aid="album.aid">
            <div class="title clearfix" @click="toggleAlbum(album)">
              <div class="float-left mr-3">
                <cover fill :book="album" style="width: 48px;"/>
              </div>
              <div class="mr-auto">
                <b>{{album.name}}</b>
                <div class="small">
                  <datetime :value="album.createdAt" format="L" prefix="创建于 "/>
                </div>
              </div>
              <b-link>
                <span v-if="album.selectedCount">已选{{album.selectedCount}} /</span>
                {{album.uploadedImagesCount}}张
                <fa :icon="active === album ? 'caret-up' : 'caret-down'"/>
              </b-link>
            </div>

            <div class="images" v-if="active === album">
              <template v-if="loading === 2">
                <p>
                  <ske width="9em"/>
                </p>
                <grid class="candidates" cols="4">
                  <grid-item class="candidate" v-for="i in 4" :key="'c' + i">
                    <ske type="square"/>
                  </grid-item>
                </grid>
              </template>

              <template v-else-if="items.length">
                <p v-if="items.filter(i => !i.added).length > 0">
                  <b-link @click="toggleAll(items)">
                    <fa icon="check-double"/>
                    选择所有 {{items.filter(i => !i.added).length}} 张
                  </b-link>
                </p>
                <grid class="candidates" cols="4">
                  <grid-item class="candidate" v-for="item in items" :key="'c' + item.pid"
                             :data-pid="item.pid">
                    <square :src="item.url + '!240'" border fit
                            @click="toggleItem(item)"
                            :disabled="!!item.added" :text="item.added ? '已导入' : ''"
                            :selected="selected.includes(item.pid)"/>
                  </grid-item>
                </grid>
              </template>

              <empty v-else icon="image">当前作品没有可导入图片哦</empty>
            </div>
          </div>
        </template>
      </div>
    </div>
    <footer>
      <div class="mb-2 text-center">
        <fa icon="check-double"/>
        当前已选 {{selected.length}} 张图片
      </div>
      <div class="buttons">
        <b-btn block @click="selected = []" v-if="selected.length">取消选择</b-btn>
        <b-btn block @click="$parent.menuBack()" v-else>返回</b-btn>
        <b-btn block @click="addImages" variant="primary"
               :disabled="!selected.length || submitting">
          <fa icon="spinner" spin v-if="submitting"/>
          确认添加
        </b-btn>
      </div>
    </footer>
  </div>
</template>

<script>
import { chain, without } from 'lodash'
import inherits from '@/mixins/inherits'

export default {
  name: 'albumPickZone',
  mixins: [
    inherits(['album', 'images', 'imageCount'])
  ],
  data() {
    return {
      loading: 0,
      submitting: false,
      pageSize: 240,
      active: null,
      albums: [],
      selected: [],
      gallery: []
    }
  },
  computed: {
    items() {
      return this.gallery.filter(i => {
        if (this.active) {
          return i.aid === this.active.aid
        }
        return true
      })
    }
  },
  beforeDestroy() {
    document.body.classList.remove('modal-open')
  },
  async created() {
    document.body.classList.add('modal-open')
    this.loading = 1
    await this.updateAlbums()
    this.loading = 0
  },
  methods: {
    toggleAlbum(item) {
      this.active = this.active === item ? '' : item
      if (this.active) {
        this.updateCandidates()
      }
    },
    async updateAlbums() {
      const results = await this.$req.get('/jianxiang/api/1/albums/my/', {
        params: {
          ordering: '-updated_at',
          size: 96
        }
      })
      this.albums = results.filter(i => i.uploadedImagesCount > 0 && i.aid !== this.album.aid)
    },
    async updateCandidates() {
      if (this.active && this.gallery.filter(i => i.aid === this.active.aid).length) {
        return
      }
      try {
        const pids = this.images.map(i => i.pid)
        this.loading = 2
        const maxPage = Math.ceil(this.active.uploadedImagesCount / this.pageSize)
        for (let i = 0; i < maxPage; i++) {
          let results = await this.$req.get('/jianxiang/api/1/album_images/my/', {
            params: {
              aid: this.active.aid,
              excludeAid: this.album.aid,
              size: this.pageSize,
              page: i + 1
            }
          })
          results = results.map(i => {
            return {
              ...i.image,
              aid: i.aid,
              used: i.used,
              added: pids.includes(i.image.pid)
            }
          })
          this.gallery.push(...results)
        }
        this.gallery = chain(this.gallery)
          .uniqBy(i => i.aid + i.pid)
          .orderBy('used', 'desc')
          .value()
      } finally {
        clearTimeout(this.t)
        this.t = setTimeout(() => {
          this.loading = 0
        }, 200)
      }
    },
    toggleItem(item) {
      this.active.selectedCount = this.active.selectedCount || 0
      if (item.added) {
        return
      }
      if (this.selected.includes(item.pid)) {
        this.selected = without(this.selected, item.pid)
        this.active.selectedCount--
        return
      }
      if (!this.selected.includes(item.pid)) {
        this.selected.push(item.pid)
        this.active.selectedCount++
      }
    },
    toggleAll(items) {
      items = items.filter(i => !i.added)
      const allSelected = items.every(i => this.selected.includes(i.pid))
      this.active.selectedCount = this.active.selectedCount || 0
      if (allSelected) {
        this.selected = this.selected.filter(pid => !items.find(i => i.pid === pid))
        this.active.selectedCount -= items.length
      } else {
        for (const item of items) {
          const pid = item.pid
          if (!this.selected.includes(pid)) {
            this.selected.push(pid)
            this.active.selectedCount++
          }
        }
      }
    },
    async addImages() {
      try {
        if (!this.selected.length) {
          return
        }
        this.submitting = true
        const aid = this.album.aid
        const data = {pids: this.selected}
        await this.$req.post('/jianxiang/api/1/albums/' + aid + '/import_images/', data)
        this.$alert.success('已添加 ' + this.selected.length + ' 张图片至当前相册')
        for (const pid of this.selected) {
          const item = this.gallery.find(i => i.pid === pid)
          if (item) {
            item.added = true
            this.images.unshift(item)
          }
          this.active.selectedCount--
        }
        this.imageCount += this.selected.length
        this.selected = []
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.album-pick-zone {
  height: 100%;

  .container {
    position: relative;
    min-height: 200px;
  }

  footer {
    padding: 1rem;
    border-top: 1px solid $hr-border-color;
  }

  .placeholder {
    background-color: #eee;
    height: 82px;
  }

  .group {
    margin-bottom: 1rem;

    &.active .title {
      background-color: $primary;
      color: #fff;

      a {
        color: #fff;
      }
    }
  }

  .title {
    cursor: pointer;
    padding: .5em 1em;
    background-color: #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
  }

  .images {
    padding: 1rem;
    padding-bottom: 0;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, .2);
    z-index: 5;
    pointer-events: none;
  }

  .candidates {
    margin-top: -5px;
  }
}
</style>
