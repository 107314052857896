<template>
  <div class="album-action-zone" :class="[operation]" :data-tid="album.tid">
    <transition name="fade-down">
      <div :class="{'bg-success': hoverOnImage, 'bg-primary': hoverOnDrop}" class="tip"
           v-if="operation">
        <template v-if="hoverOnImage === 'swap'">
          <fa icon="exchange"/>
          松手交换图片
        </template>
        <template v-else-if="hoverOnDrop">
          <fa icon="level-down"/>
          松手卸下图片
        </template>
        <template v-else-if="operation === 'down'">拖动到其他图片区域可以直接交换哦</template>
      </div>
    </transition>

    <div class="actions" v-if="album.tid === 'picturebook'">
      <b-btn @click="$parent.showMenu('avatar')" variant="success">上传宝贝头像</b-btn>
      <div class="right">
        <b-btn @click="$parent.showMenu('avatar')">修改宝贝昵称</b-btn>
        <b-btn @click="$parent.showMenu('avatar')">修改作品名</b-btn>
      </div>
    </div>

    <div class="actions" v-else-if="album.tid === 'frame-moment'">
      <b-btn @click="$parent.showMenu('frame')" variant="success">修改内容</b-btn>
      <div class="right">
        <b-btn @click="$parent.showMenu('style')">更换风格</b-btn>
      </div>
    </div>

    <div class="actions" v-else-if="/calendar/.test(album.tid)">
      <div style="line-height: 33px;">...</div>
    </div>

    <div class="actions" v-else-if="activeIndex === 'cover'">
      <b-btn variant="success" @click="$parent.showMenu('coverStyle')">切换封面</b-btn>
      <div class="right">
        <b-btn @click="$parent.showMenu('coverImage')">更换图片</b-btn>
        <b-btn @click="$parent.showMenu('coverText')">修改标题</b-btn>
      </div>
    </div>
    <div class="actions" v-else-if="movingPage">
      <b-btn variant="success" @click="movePage(-1)" :disabled="activeIndex <= 0 || moving">
        前移
      </b-btn>
      <b-btn variant="success" @click="movePage(1)"
             :disabled="activeIndex >= albumPages.length - 1 || moving">
        后移
      </b-btn>
      <div class="right">
        <b-btn @click="toggleMove">完成</b-btn>
      </div>
    </div>
    <div class="actions" v-else>
      <b-btn variant="success" @click="$parent.showMenu('template')">更换版式</b-btn>
      <div class="right">
        <b-btn @click="toggleMove">移动</b-btn>
        <b-btn @click="$parent.showMenu('text')" :disabled="!hasText">文本</b-btn>
        <b-btn @click="$parent.deletePage(activeIndex)" :disabled="!!$parent.deleting">删除</b-btn>
      </div>
    </div>

    <div :class="{hover: hoverOnDrop}" class="drop-zone" v-if="operation === 'down'">
      <div class="content">
        <div class="mb-2">
          <fa icon="level-down" size="2x"/>
        </div>
        <div>将图片拖动到此处卸下</div>
      </div>
    </div>

    <move-area ref="moveArea" v-model="adjustingImage" :zoom="!addingText" v-if="adjustingImage">
      <template v-slot:buttons="{cancel}">
        <b-row class="buttons" v-if="!addingText">
          <b-col>
            <b-btn @click="cancel" class="w-100">
              取消
            </b-btn>
          </b-col>
          <b-col>
            <b-btn @click="downImage(adjustingImage.id)" class="w-100">
              <fa fw icon="level-down"/>
              卸下
            </b-btn>
          </b-col>
          <b-col>
            <b-btn @click="addingText = true" class="w-100">
              <fa fw icon="font"/>
              注释
            </b-btn>
          </b-col>
          <b-col>
            <b-btn @click="saveAdjustment" class="w-100" variant="primary">
              <fa fw icon="check"/>
              确认
            </b-btn>
          </b-col>
        </b-row>
        <b-input-group v-else>
          <b-input placeholder="在此处输入图片注释（最多 24 字）" maxlength="24" v-model="adjustingImage.text"
                   autofocus/>
          <b-btn @click="saveText" slot="append">
            <fa icon="spinner" spin v-if="savingText"/>
            <fa icon="check" v-else/>
          </b-btn>
        </b-input-group>
      </template>
    </move-area>
  </div>
</template>

<script>
import { find, forEach, isEqual, uniq } from 'lodash'

import MoveArea from '@/components/MoveArea'
import inherits from '@/mixins/inherits'

export default {
  name: 'albumActionZone',
  components: {MoveArea},
  mixins: [
    inherits(
      'album',
      'albumPages',
      'layers',
      'images',
      'menu',
      'activeIndex',
      'movingPage',
      'canEdit'
    )
  ],
  data() {
    return {
      containerSelector: '.wrapper',
      t: null,
      gestureDown: false,
      gestureMoved: false,
      operation: '',
      hoverOnDrop: false,
      hoverOnImage: '',
      addingText: false,
      savingText: false,
      moving: false,
      adjustingImage: null
    }
  },
  computed: {
    hasText() {
      return this.albumPages[this.activeIndex]?.layers.filter(l => l.type === 'text').length > 0
    },
    aid() {
      return this.album.aid
    }
  },
  created() {
    const isSpecial = ['picturebook'].includes(this.album.tid)
    if (this.canEdit && !isSpecial) {
      this.init()
    }
  },
  beforeDestroy() {
    const container = document.querySelector(this.containerSelector)
    container.removeEventListener('touchstart', this.onGestureStart)
    container.removeEventListener('touchmove', this.onGestureMove)
    container.removeEventListener('touchend', this.onGestureEnd)
    container.removeEventListener('mousedown', this.onGestureStart)
    container.removeEventListener('mousemove', this.onGestureMove)
    container.removeEventListener('mouseup', this.onGestureEnd)
    container.removeEventListener('contextmenu', this.onContextmenu)
  },
  methods: {
    init() {
      const container = document.querySelector(this.containerSelector)
      container.addEventListener('contextmenu', this.onContextmenu)
      if ('ontouchstart' in window) {
        container.addEventListener('touchstart', this.onGestureStart)
        container.addEventListener('touchmove', this.onGestureMove)
        container.addEventListener('touchend', this.onGestureEnd)
      } else {
        container.addEventListener('mousedown', this.onGestureStart)
        container.addEventListener('mousemove', this.onGestureMove)
        container.addEventListener('mouseup', this.onGestureEnd)
      }
    },
    toggleMove() {
      this.movingPage = !this.movingPage
      if (this.movingPage) {
        this.$broadcast('scrollToActive')
      }
    },
    async movePage(offset) {
      this.moving = true
      const oldIndex = this.activeIndex
      const newIndex = Math.max(0, Math.min(this.albumPages.length - 1, this.activeIndex + offset))
      const arr = this.albumPages
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
      this.activeIndex = newIndex
      await this.$parent.swapPage({oldIndex, newIndex})
      this.moving = false
    },

    onContextmenu(e) {
      const t = this.getDelegate(e, '.image[name^="pic"]')
      if (t) {
        e.preventDefault()
        return false
      }
    },
    onGestureStart(e) {
      let threshold = 250
      this.gestureDown = true
      this.gestureMoved = false

      if (e.type === 'mousedown' && e.button !== 0) {
        return
      }

      if (!window.isTouchDevice) {
        threshold = 200
      }

      // 页面中的图片
      let t = this.getDelegate(e, '.image[name^="pic"]')
      if (t) {
        this.t = t
        const touch = e.touches ? e.touches[0] : e
        if (touch) {
          t.startX = touch.clientX
          t.startY = touch.clientY
        }
        this.timeout = setTimeout(() => {
          try {
            clearTimeout(this.timeout)
            console.log('down')
            this.operation = 'down'

            // 复制元素并插入到 body 中
            const bound = t.getBoundingClientRect()
            this.t = t.cloneNode(true)
            this.t.classList.add('image-ghost')
            this.t.source = t
            this.t.originalTransform = this.t.style.transform

            t.style.opacity = 0.5

            Object.assign(this.t.style, {
              position: 'fixed',
              top: bound.top + 'px',
              left: bound.left + 'px',
              width: bound.width + 'px',
              height: bound.height + 'px'
            })

            document.querySelector(this.containerSelector).appendChild(this.t)

            const touch = e.touches ? e.touches[0] : e
            if (touch) {
              this.t.startX = touch.clientX
              this.t.startY = touch.clientY
            }
          } catch (err) {
            console.error(err)
          }
        }, threshold)
        return
      }

      t = this.getDelegate(e, '.text[name^="text"]')
      if (t) {
        this.t = t
      }
    },
    onGestureMove(e) {
      if (!this.t) {
        return
      }

      const touch = e.touches ? e.touches[0] : e
      const {clientX, clientY} = touch

      const x = clientX - this.t.startX
      const y = clientY - this.t.startY

      const tolerance = 10

      if (Math.abs(x) > tolerance || Math.abs(y) > tolerance) {
        this.gestureMoved = true
        clearTimeout(this.timeout)
      }

      if (!this.t.source) {
        return
      }

      // 拖动图片
      e.preventDefault()
      e.stopPropagation()

      this.t.style.transform = `translate(${x}px, ${y}px)`

      const els = document.elementsFromPoint(clientX, clientY)
      if (this.operation === 'down') {
        this.hoverOnDrop = els.some(el => el.classList.contains('drop-zone'))

        let imageHovered = els
          .filter(el => el !== this.t)
          .filter(el => el !== this.t.source)
          .find(el => el.matches('.image[name^="pic"]'))

        if (els.some(el => el === this.$el)) {
          imageHovered = null
        }

        if (imageHovered) {
          this.hoverOnImage = imageHovered.dataset.default === 'false' ? 'swap' : 'add'
        } else {
          this.hoverOnImage = ''
        }
      }
    },
    onGestureEnd(e) {
      this.gestureDown = false
      clearTimeout(this.timeout)

      try {
        if (!this.t) {
          return
        }

        if (this.t.classList.contains('text')) {
          this.$parent.showMenu('text')
          return
        }

        const {changedTouches} = e
        const touch = changedTouches ? changedTouches[changedTouches.length - 1] : e
        const {clientX, clientY} = touch
        const els = document.elementsFromPoint(clientX, clientY)

        if (this.operation === 'down') {
          // 从页面拖动图片
          const dropzone = els.find(el => el.matches('.drop-zone'))
          const imageEl = els.find(el => el !== this.t && el.matches('.image[name^="pic"]'))

          const source = this.t.source
          const layerId = source.id

          if (dropzone) {
            source.style.backgroundImage = null
            source.style.backgroundColor = '#eee'
            this.downImage(layerId)
          } else if (imageEl) {
            this.swapImage(source.dataset.index, imageEl.dataset.index)
          }

          return
        }

        if (this.gestureMoved) {
          return
        }

        if (this.t.id) {
          this.initAdjusting(this.t)
        } else {
          if (/pick/.test(this.menu)) {
            this.$parent.menuBack()
          } else {
            this.$parent.showMenu('pick#' + this.t.getAttribute('name'))
          }
        }
      } finally {
        this.resetT()
      }
    },
    resetT() {
      if (!this.t) {
        return
      }
      if (this.t.source) {
        this.t.source.style.opacity = null
        this.t.remove()
      }
      this.t = null
      this.operation = ''
      this.hoverOnImage = ''
      this.hoverOnDrop = false
      forEach(document.querySelectorAll('.image-ghost'), el => el.remove())
    },

    async initAdjusting(t) {
      if (!t) {
        return
      }
      const pid = t.id
      const layer = this.layers.find(l => l.id === pid)
      if (!layer) {
        throw new Error('Layer not found')
      }

      const src = layer.content
      const {w, h} = t.dataset
      this.adjustingImage = {
        id: layer.id,
        pageIndex: layer.pageIndex,
        src,
        text: layer.text,
        dw: layer.w,
        dh: layer.h,
        w,
        h,
        mode: parseInt(t.dataset?.mode),
        x: layer.x,
        y: layer.y,
        rotate: layer.rotate || 0,
        scale: layer.scale || '100%'
      }
    },
    saveText() {
      const {text, pageIndex, id} = this.adjustingImage
      const layer = this.layers.find(l => l.id === id)
      layer.text = text || ''
      this.savingText = true
      const data = {
        index: pageIndex,
        pid: id,
        text: text
      }
      return this.$parent.sendOperation('modify_image', data).then(() => {
        this.savingText = false
        this.addingText = false
      }).catch(() => {
        this.savingText = false
      })
    },
    saveAdjustment() {
      const {x, y, scale, rotate, pageIndex, id} = this.adjustingImage
      const layer = this.layers.find(l => l.id === id)
      const t = document.getElementById(id)
      const bgPos = x + ' ' + y

      this.adjustingImage = null
      this.operation = ''
      this.t = null

      if (!layer) {
        return
      }

      this.$nextTick(() => {
        const el = document.querySelector(`[data-page="${pageIndex}"]`)
        if (el) {
          el.scrollIntoViewIfNeeded ? el.scrollIntoViewIfNeeded() : el.scrollIntoView()
        }
      })

      if (isEqual([x, y, scale, rotate], [layer.x, layer.y, layer.scale, layer.rotate])) {
        return
      }

      t.style.backgroundPosition = bgPos

      layer.x = x
      layer.y = y
      layer.scale = scale
      layer.rotate = rotate

      this.$parent.syncPage(pageIndex)
    },

    getDelegate(e, selector) {
      let target = e.target
      while (target) {
        if (!target) {
          return null
        }
        if (target.matches(selector)) {
          return target
        }
        target = target.parentElement
      }
    },
    swapImage(source, target) {
      if (source === target) {
        return
      }
      const sourceLayer = this.layers.find(l => l.index === source)
      const targetLayer = this.layers.find(l => l.index === target)
      const {
        id: sId,
        content: sContent,
        x: sX,
        y: sY,
        text: sText,
        rotate: sRotate,
        scale: sScale
      } = sourceLayer
      const {
        id: tId,
        content: tContent,
        x: tX,
        y: tY,
        text: tText,
        rotate: tRotate,
        scale: tScale
      } = targetLayer

      sourceLayer.id = tId
      sourceLayer.content = tContent
      sourceLayer.x = tX
      sourceLayer.y = tY
      sourceLayer.text = tText || ''
      sourceLayer.rotate = tRotate || 0
      sourceLayer.scale = tScale || '100%'

      targetLayer.id = sId
      targetLayer.content = sContent
      targetLayer.x = sX
      targetLayer.y = sY
      targetLayer.text = sText || ''
      targetLayer.rotate = sRotate || 0
      targetLayer.scale = sScale || '100%'

      const sourceEl = document.querySelector('[data-index="' + source + '"]')
      const targetEl = document.querySelector('[data-index="' + target + '"]')

      const sSize = parseInt(sourceEl.dataset.size) || 800
      const tSize = parseInt(targetEl.dataset.size) || 800

      sourceEl.style.backgroundImage = tContent ? 'url(' + tContent + '!' + sSize + ')' : ''
      sourceEl.style.backgroundPositionX = tX
      sourceEl.style.backgroundPositionY = tY
      sourceEl.innerHTML = '' // 清除文字
      targetEl.style.backgroundImage = sContent ? 'url(' + sContent + '!' + tSize + ')' : ''
      targetEl.style.backgroundPositionX = sX
      targetEl.style.backgroundPositionY = sY
      targetEl.innerHTML = ''

      if (tContent) {
        sourceEl.classList.remove('empty')
      }
      if (sContent) {
        targetEl.classList.remove('empty')
      }

      const indexes = uniq([sourceLayer.pageIndex, targetLayer.pageIndex])
      this.$parent.syncPage(indexes)
      this.operation = ''
    },
    async downImage(layerId) {
      const layer = find(this.layers, {id: layerId})
      if (!layer) {
        console.error('Layer not found during down image')
        return
      }

      const image = {
        pid: layer.id,
        url: layer.content.split('!')[0],
        width: Infinity,
        height: Infinity
      }

      document.getElementById(layer.id).dataset.default = 'true'

      layer.id = null
      layer.content = null
      layer.x = null
      layer.y = null
      this.adjustingImage = null

      this.images.unshift(image)

      this.$parent.syncPage(layer.pageIndex)
      this.operation = ''

      const results = await this.$req.get(`/jianxiang/api/1/albums/${this.album.aid}/images/`, {
        params: {
          pid: image.pid
        }
      })
      const result = results[0]
      image.width = result.width
      image.height = result.height
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip.drag {
  left: 1rem;
  bottom: 100%;
  margin-bottom: -1rem;
}

.buttons {
  white-space: nowrap;
  margin-left: -5px;
  margin-right: -5px;

  .col {
    padding-left: 5px;
    padding-right: 5px;
    min-width: 0;
  }
}

.album-action-zone {
  background-color: #fff;

  &[data-tid^="calendar"] {
    .actions {
      display: none;
    }
  }

  &.down {
    padding-bottom: 0;
    z-index: 25;
  }

  .container {
    padding-top: 2px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .drop-zone {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 30;
    height: 100%;
    min-height: 120px;
    padding: $grid-gutter-width / 2;
    background-color: #fff;

    &.hover {
      .content {
        border-color: $primary;
        color: $primary
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      padding: 2rem 1rem;
      border: 1px dashed $hr-border-color;
      color: $text-muted;
      height: 100%;
    }

    ~ .images, ~ .trash-zone {
      display: none;
    }
  }

  header {
    text-align: right;
    background-color: #fff;
  }

  .small-tip {
    float: left;
    color: $text-muted;
  }

  .progress-label {
    color: $text-muted;
  }
}

.actions {
  padding: 8px;
  border-top: 1px solid $hr-border-color;
  @include clearfix();

  @include media-breakpoint-up(sm) {
    padding: 0;
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    border-top: 0;
    border-bottom: 1px solid $hr-border-color;

    &:before {
      display: block;
      margin-bottom: .5em;
      content: '— 当前页操作 —';
      text-align: center;
      color: $text-muted;
    }

    .btn {
      width: 100%;
      margin-bottom: .5rem;
    }
  }

  .right {
    @include media-breakpoint-down(sm) {
      float: right;
    }
  }
}
</style>

<style lang="scss">
.image-ghost {
  position: fixed;
  box-shadow: 0 0 20px rgba(0, 0, 0, .3) !important;
  opacity: .5;
  margin: 0;
  z-index: 100;
  user-select: none;

  * {
    display: none !important;
  }
}
</style>
