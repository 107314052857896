<template>
  <div class="album-frame-zone album-menu-zone">
    <div class="container scrollable">
      <b-form-group>
        <div class="mb-2">
          请上传宝贝正面照，系统会自动分割出头像。为保证头像尺寸，建议先对图片进行裁切保留头部附近区域。
          <b-link v-b-modal="'example'">查看示例</b-link>
        </div>
        <b-btn block variant="primary" @click="uploadAvatar" :disabled="storage.uploading">
          <fa icon="spinner" spin v-if="storage.uploading"/>
          {{avatar ? '更换宝贝照片' : '上传宝贝照片'}}
        </b-btn>
      </b-form-group>

      <b-modal id="example" title="图片示例" hide-footer>
        <div class="mb-3">
          <h4>
            <fa icon="check" class="text-success"/>
            建议的图片
          </h4>
          <square src="https://img.xinshu.me/upload/5a879a02435e476e9bc6963554fd3a35"
                  height="100%"/>
        </div>
        <div>
          <h4>
            <fa icon="times" class="text-danger"/>
            不建议的图片
          </h4>
          <square src="https://img.xinshu.me/upload/2f520082e8a24f23bdc38b0b60d5c9f2"
                  height="66.7%"/>
        </div>
      </b-modal>

      <b-row>
        <b-col cols="6" sm="12">
          <b-form-group label="宝贝昵称">
            <b-input placeholder="填写宝贝昵称" v-model="nickname" @change="saveNickname" maxlength="20"/>
          </b-form-group>
        </b-col>
        <b-col cols="6" sm="12">
          <b-form-group label="作品名称">
            <b-input placeholder="填写作品名称" v-model="title" @change="saveTitle" maxlength="20"/>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <footer>
      <b-btn block @click="$parent.menuBack()">返回</b-btn>
    </footer>
  </div>
</template>

<script>
import inherits from '@/mixins/inherits'
import Upload from '@/models/upload'

export default {
  name: 'albumAvatarZone',
  mixins: [inherits(['album', 'albumPages', 'layers'])],
  data() {
    return {
      storage: new Upload({
        uploadUrl: 'https://media2.xinshu.me/upload/avatar',
        minSize: '0x0'
      }),
      nickname: '',
      avatar: '',
      title: ''
    }
  },
  computed: {
    meta() {
      return this.album.meta || {}
    },
    aid() {
      return this.album.aid
    }
  },
  created() {
    this.nickname = this.layers.find(i => i.name === 'text')?.content || ''
    this.avatar = this.layers.find(i => i.name === 'pic')?.content || ''
    this.title = this.album.name
  },
  methods: {
    async uploadAvatar() {
      const result = await this.storage.upload()
      if (!result) {
        return
      }
      const [image] = await this.$req.post(
        `/jianxiang/api/1/albums/${this.album.aid}/activities/`,
        {
          images: [result],
          uploadOnly: true
        }
      )
      for (const layer of this.layers) {
        if (layer.name === 'pic') {
          layer.id = image.pid
          layer.y = '100%'
        }
      }
      this.avatar = result.url
      const cover = {...this.album.cover, pic: image.url}
      await this.$parent.saveAlbum({cover})
      this.album.cover = cover
      this.$parent.syncPage(this.albumPages.map(p => p.pageIndex))
    },
    async saveNickname() {
      for (const layer of this.layers) {
        if (layer.name === 'text') {
          layer.content = this.nickname
        }
      }
      await this.$parent.syncPage(this.albumPages.map(p => p.pageIndex))
      this.$alert.success('宝贝昵称已保存')
    },
    async saveTitle() {
      await this.save({name: this.title})
      this.album.title = this.title
      this.$alert.success('作品名称已保存')
    }
  }
}
</script>

<style lang="scss" scoped>
.album-frame-zone {
  @include media-breakpoint-up(sm) {
    height: auto;
    border: 1px solid $hr-border-color;
  }

  header {
    margin-bottom: 1rem;
  }

  .body {
    padding-top: 0;
  }

  .covers {
    max-height: 165px;
    overflow: auto;
    margin-bottom: 1rem;
    -webkit-overflow-scrolling: touch;
    @include media-breakpoint-down(sm) {
      flex-wrap: nowrap;
      overflow: auto;
      .square {
        min-width: 64px;
      }
    }
  }

  .style-tag {
    position: absolute;
    top: 4px;
    left: 0;
    width: 2.5em;
    line-height: 1.8;
    border-radius: 0 100px 100px 0;
    z-index: 0;
    background-color: $success;
    color: #fff;
    text-align: center;
    font-size: 12px;
  }

  .bottom {
    display: block;
    position: relative;
  }
}
</style>
