<template>
  <div class="album-frame-zone album-menu-zone">
    <div class="container scrollable">
      <b-row>
        <b-col cols="12">
          <b-form-group label="头像昵称">
            <b-row>
              <b-col cols="3">
                <upload-zone hide-exceeded cols="1" simple max="1" v-model="avatar"/>
              </b-col>
              <b-col>
                <b-input placeholder="昵称" v-model="nickname"/>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="拍摄日期">
            <b-input v-model="date" type="date"/>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="文本">
            <textarea class="form-control" rows="3" v-model="text" :maxlength="textarea.maxLength"/>
            <div class="lines"></div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="拍摄地点">
        <b-row>
          <b-col>
            <b-input v-model="city" placeholder="城市"/>
          </b-col>
          <b-col>
            <b-input v-model="site" placeholder="地点（景点、街道、建筑等）"/>
          </b-col>
        </b-row>
      </b-form-group>
    </div>
    <footer>
      <b-btn @click="save" block variant="primary" :disabled="saving">
        <fa icon="spinner" spin v-if="saving"/>
        保存并返回
      </b-btn>
    </footer>
  </div>
</template>

<script>
import inherits from '@/mixins/inherits'
import UploadZone from '@/components/UploadZone'
import getLines from '@/utils/get-lines'

export default {
  name: 'albumFrameZone',
  components: {UploadZone},
  mixins: [inherits('album', 'albumPages')],
  data() {
    return {
      saving: false,
      text: '',
      date: '',
      city: '',
      site: '',
      nickname: '',
      avatar: '',
      template: ''
    }
  },
  computed: {
    aid() {
      return this.album.aid
    },
    page() {
      return this.albumPages[0]
    },
    textarea() {
      return getLines(this.text, {maxRows: 4, cols: 26})
    }
  },
  created() {
    const meta = this.album.meta
    this.date = meta.date
    this.city = meta.city
    this.site = meta.site
    this.nickname = meta.nickname
    this.avatar = meta.avatar
    this.text = this.page.layers.find(l => l.type === 'text').content
  },
  methods: {
    async save() {
      try {
        const meta = {...this.album.meta}
        meta.date = this.date
        meta.city = this.city
        meta.site = this.site
        meta.nickname = this.nickname
        meta.avatar = this.avatar
        this.page.layers.find(l => l.type === 'text').content = this.text
        this.saving = true
        await Promise.all([
          this.$parent.syncPage(0),
          this.$parent.saveAlbum({meta})
        ])
        this.$alert.success('内容已保存')
        this.$parent.menuBack()
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.album-frame-zone {
  @include media-breakpoint-up(sm) {
    height: auto;
    border: 1px solid $hr-border-color;
  }

  header {
    margin-bottom: 1rem;
  }

  .body {
    padding-top: 0;
  }

  .covers {
    max-height: 165px;
    overflow: auto;
    margin-bottom: 1rem;
    -webkit-overflow-scrolling: touch;
    @include media-breakpoint-down(sm) {
      flex-wrap: nowrap;
      overflow: auto;
      .square {
        min-width: 64px;
      }
    }
  }

  .style-tag {
    position: absolute;
    top: 4px;
    left: 0;
    width: 2.5em;
    line-height: 1.8;
    border-radius: 0 100px 100px 0;
    z-index: 0;
    background-color: $success;
    color: #fff;
    text-align: center;
    font-size: 12px;
  }

  .bottom {
    display: block;
    position: relative;
  }
}
</style>
