<template>
  <div class="album-share-zone">
    <p class="text-muted" v-if="album.isLike">你已经为 TA 点过赞了哦</p>
    <p class="text-muted" v-else>喜欢 TA 的作品就点个赞吧</p>

    <p>
      <b-btn :disabled="album.isLike || saving" :variant="album.isLike ? 'primary' : 'outline-primary'"
             @click="like" class="rounded-pill" size="lg">
        <fa :far="!album.isLike" icon="thumbs-up"/>
        {{album.likeCount}}
      </b-btn>
    </p>
    <template v-if="album.likes && album.likes.length > 0">
      <b-card>
        <p class="text-muted">以下是最近点赞的好友</p>
        <b-row>
          <b-col cols="3" sm="2" :key="item.uid" v-for="item in album.likes">
            <avatar :src="item.avatar" class="d-block"></avatar>
            <div class="small text-overflow mt-1">{{item.nickname}}</div>
          </b-col>
        </b-row>
      </b-card>
    </template>
    <b-card v-else>
      <empty class="p-0" icon="users">
        还没有好友点赞哦
      </empty>
    </b-card>
  </div>
</template>

<script>
import inherits from '@/mixins/inherits'

export default {
  name: 'albumShareZone',
  mixins: [inherits('album')],
  data() {
    return {
      saving: false
    }
  },
  created() {
    if (this.$route.query.like && !this.album.isLike) {
      this.like()
    }
    this.addReadCount()
  },
  methods: {
    addReadCount() {
      this.album.readCount++
      return this.$req.post('/jianxiang/api/1/albums/' + this.album.aid + '/read/')
    },
    async like() {
      if (!this.hasLogin) {
        const confirmed = await this.$dialog.confirm({
          title: '请先登录',
          content: '您需要先登录才能继续点赞哦',
          okTitle: '前往登录',
          cancelTitle: '算了'
        })
        if (confirmed) {
          this.$router.push('/signin?auto=1&redirect=' + encodeURIComponent(this.$route.path + '?mode=s&like=1'))
        }
        return
      }
      try {
        this.saving = true
        await this.$req.post('/jianxiang/api/1/albums/' + this.album.aid + '/like/')
        this.album.likeCount++
        this.album.likes.unshift(this.user)
        this.album.isLike = true
        this.$alert.success('点赞成功，感谢您的支持')
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .album-share-zone {
    text-align: center;
  }
</style>
