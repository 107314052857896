<template>
  <div class="album" :class="{editable: canEdit, 'menu-active': !!menu}"
       :data-mode="previewMode" :data-menu="menu" :data-tid="album.tid">
    <floating-guide/>
    <loading type="page" v-if="loading"/>
    <template v-else>
      <header class="navigation">
        <b-row align-v="center">
          <b-col cols="auto" v-if="!isAgentUser && canEdit">
            <b-btn size="sm" @click="backToHome">
              <fa icon="arrow-turn-down-left"/>
              书架
            </b-btn>
          </b-col>
          <b-col class="text-center">{{pagination}} / {{albumPages.length}}</b-col>
          <b-col cols="auto" v-if="!isAgentUser && canEdit">
            <qiyu size="sm" variant="secondary">
              客服
            </qiyu>
          </b-col>
        </b-row>
      </header>

      <component
        class="previewer"
        :is="previewerComponent"
        v-model="activeIndex"
        :album="album"
        :pages="albumPages"
        :editable="canEdit"
      />

      <!-- 需要一直在后台启用的菜单 -->
      <transition name="slide-up">
        <component :is="menuComponent" v-if="menuComponent"/>
      </transition>

      <album-navigation-zone v-if="album.tid !== 'frame-moment'"/>

      <div class="dimmer" @click="menuBack"></div>

      <footer class="album-footer-menu">
        <component :is="actionComponent" v-if="actionComponent"/>
        <template v-if="navItems.length">
          <bottom-bar :items="navItems" nav/>
          <side-menu :items="navItems"/>
        </template>
      </footer>
    </template>
  </div>
</template>

<script>
import { chain, cloneDeep, isEmpty, pick } from 'lodash'
import Upload from '@/models/upload'
import routeData from '@/mixins/route-data'
import reloadNeeded from '@/mixins/reload-needed'
import { configShare } from '@/modules/wechat'
import { extendAlbum } from '@/models/album'

export default {
  name: 'album',
  title: '预览照片书',
  components: {
    SideMenu: require('@/components/SideMenu').default,
    FloatingGuide: require('@/components/FloatingGuide').default,
    AlbumPreviewer: require('./Previewer/AlbumPreviewer').default,
    NotePreviewer: require('./Previewer/NotePreviewer').default,
    FramePreviewer: require('./Previewer/FramePreviewer').default,

    AlbumNavigationZone: require('./Album/AlbumNavigationZone').default,
    AlbumNoteZone: require('./Album/AlbumNoteZone').default,
    AlbumTextZone: require('./Album/AlbumTextZone').default,
    AlbumImportZone: require('./Album/AlbumImportZone').default,
    AlbumPickZone: require('./Album/AlbumPickZone').default,
    AlbumShareMenu: require('./Album/AlbumShareMenu').default,
    AlbumCoverZone: require('./Album/AlbumCoverZone').default,
    AlbumStyleZone: require('./Album/AlbumStyleZone').default,
    AlbumGuideZone: require('./Album/AlbumGuideZone').default,
    AlbumShareZone: require('./Album/AlbumShareZone').default,
    AlbumActionZone: require('./Album/AlbumActionZone').default,
    AlbumShuffleZone: require('./Album/AlbumShuffleZone').default,
    AlbumTemplateZone: require('./Album/AlbumTemplateZone').default,
    AlbumFrameZone: require('./Album/AlbumFrameZone').default,
    AlbumGalleryZone: require('./Album/AlbumGalleryZone').default,
    AlbumAvatarZone: require('./Album/AlbumAvatarZone').default
  },
  mixins: [
    routeData('album'),
    routeData('albumPages'),
    reloadNeeded
  ],
  data() {
    return {
      uploading: false,
      addingPage: false,
      movingPage: false,

      images: [],
      tmpPages: [],
      gallery: {
        data: [],
        page: 1,
        pageSize: 12,
        totalCount: 0
      },
      activeText: null,
      activeIndex: 'cover',
      uploadOnly: false,
      newlyAdded: null,
      menu: '',
      sharing: false,
      prevMenus: [],
      storage: new Upload({minSize: '640x640'}),

      zooming: null
    }
  },
  events: {
    uploadImages: 'uploadImages'
  },
  mounted() {
    this.onContextMenu = (e) => {
      if (e.target && /INPUT|TEXTAREA/.test(e.target.tagName)) {
        return
      }
      e.preventDefault()
      e.stopPropagation()
    }
    this.$el.addEventListener('contextmenu', this.onContextMenu)
    document.addEventListener('keyup', this.onKeyUp, false)
  },
  computed: {
    previewNavItems() {
      return [
        {
          text: '返回',
          icon: 'home',
          maxWidth: '6em',
          onClick: this.backToHome,
          show: !this.isAgentUser && this.hasLogin,
          divide: true
        },
        {
          text: '我也做一本',
          icon: 'book',
          variant: 'primary',
          to: '/create/albums?tab=products&product=' + this.album.tid
        }
      ]
    },
    editNavItems() {
      return [
        {
          text: '风格',
          icon: 'palette',
          onClick: () => this.showMenu('style'),
          show: this.canChangeStyle
        },
        {
          text: '图库',
          icon: 'images',
          onClick: () => this.showMenu('gallery'),
          show: this.canManageGallery
        },
        {
          text: this.tmpPages.length ? '确认排版' : '排版',
          icon: 'sync',
          dotted: this.tmpPages.length > 0,
          onClick: () => this.showMenu('shuffle'),
          show: this.canShufflePage
        },
        {
          text: '加页',
          icon: 'plus-circle',
          onClick: () => this.showMenu('addPage'),
          show: this.canManagePage
        },
        {
          text: '购买',
          icon: 'shopping-cart',
          variant: 'primary',
          to: '/books/' + this.album.bookId + '/buy',
          show: !this.$route.query.tmptoken
        }
      ]
    },
    navItems() {
      if (this.album.locked) {
        return this.previewNavItems
      }
      if (this.isOwner) {
        return this.editNavItems
      }
      return this.previewNavItems
    },

    actionComponent() {
      if (!this.canEdit) {
        return ''
      }
      if (this.album.tid === 'note') {
        return 'album-note-zone'
      }
      if (!this.loading) {
        return 'album-action-zone'
      }
      return ''
    },
    menuComponent() {
      const menu = (this.menu || '').split('#')[0]
      return {
        coverText: 'album-cover-zone',
        coverStyle: 'album-cover-zone',
        coverImage: 'album-cover-zone',
        template: 'album-template-zone',
        addPage: 'album-template-zone',
        style: 'album-style-zone',
        note: 'album-note-zone',
        text: 'album-text-zone',
        import: 'album-import-zone',
        pick: 'album-pick-zone',
        shuffle: 'album-shuffle-zone',
        frame: 'album-frame-zone',
        addImage: 'album-gallery-zone',
        gallery: 'album-gallery-zone',
        avatar: 'album-avatar-zone'
      }[menu]
    },
    previewerComponent() {
      if (/calendar|note/.test(this.album.tid)) {
        return 'NotePreviewer'
      }
      if (/frame/.test(this.album.tid)) {
        return 'FramePreviewer'
      }
      return 'AlbumPreviewer'
    },
    previewMode() {
      const {query} = this.$route
      if (query.preview !== undefined) {
        return 'p' // preview
      }
      if (!this.canEdit && !query.mode) {
        return 'p'
      }
      return query.mode || null
    },
    imageCount: {
      get() {
        return this.album.uploadedImagesCount
      },
      set(val) {
        this.album.uploadedImagesCount = val
      }
    },
    isEmpty() {
      return this.imageCount === 0
    },
    isOwner() {
      return this.album.isOwner
    },

    hasCover() {
      if (/calendar/.test(this.album.tid)) {
        return false
      }
      return !['postcard', 'frame-moment', 'note'].includes(this.album.tid)
    },
    canEdit() {
      if (this.album.locked) {
        return false
      }
      return this.album.isOwner || this.token === 'agent_' + this.album.aid
    },
    canManageGallery() {
      return !['picturebook', 'note'].includes(this.album.tid)
    },
    canChangeStyle() {
      const disabledProducts = ['xcalbum', 'calbum', 'balbum', 'salbum', 'xsalbum']
      return !disabledProducts.includes(this.album.tid)
    },
    canManagePage() {
      return [
        'album',
        'fabric-album',
        'square-album',
        'calbum',
        'balbum',
        'salbum',
        'xsalbum',
        'xcalbum',
        'xalbum',
        'lsalbum',
        'postcard'
      ].includes(this.album.tid.replace('wm-', ''))
    },
    canShufflePage() {
      return [
        'album',
        'fabric-album',
        'square-album',
        'calbum',
        'balbum',
        'salbum',
        'xsalbum',
        'xcalbum',
        'xalbum',
        'lsalbum',
        'b5album',
        'postcard',
        'calendar-2022'
      ].includes(this.album.tid.replace('wm-', ''))
    },
    layers() {
      const layers = []
      for (const page of this.albumPages) {
        layers.push(...page.layers)
      }
      return layers
    },

    pagination() {
      const index = this.activeIndex
      if (index === 'cover') {
        return '封面'
      }
      if (index === 'copyright') {
        return '版权页'
      }
      if (index === 'acknowledgement') {
        return '致谢'
      }
      if (index === 'preface') {
        return '序言'
      }
      return index + 1
    }
  },
  methods: {
    onLoad() {
      this.$setTitle(this.album.name)

      configShare({
        title: this.album.name,
        desc: this.album.cover?.subtitle || '献给那些美好的回忆',
        imgUrl: 'https://canvas.xinshu.me/generate/cover-wxbook-preview.' + this.album.tid +
          '?pic=' + encodeURIComponent(this.getCoverUrl(this.album)),
        link: 'https://weixinshu.com/albums/' + this.album.aid + '?mode=s'
      })

      if (this.canEdit) {
        this.updateImages()

        if (this.album.tid === 'note') {
          this.$watch('album.cover', {
            deep: true,
            handler: this.syncNotePages
          })
        }
      }

      if (!this.hasCover) {
        this.activeIndex = 0
      }
    },
    async updateImages() {
      this.images = await this.$ajax.fetchUnusedImages({albumId: this.album.aid})
    },
    showMenu(menu) {
      if (!this.canEdit) {
        this.menu = ''
        return
      }

      this.prevMenus.push({menu: this.menu})
      this.menu = menu
    },
    menuBack() {
      const last = this.prevMenus.pop()
      const menu = last?.menu
      if (!menu) {
        this.menu = ''
      } else {
        this.menu = menu
      }
    },
    backToHome() {
      if (this.isMiniProgram) {
        window.wx.miniProgram.navigateBack()
        return
      }
      this.$router.push('/books?bookType=albums')
    },
    async cloneAlbum() {
      const confirmed = await this.$dialog.confirm('是否要复制当前作品到自己的书架')
      if (!confirmed) {
        return
      }
      const album = this.album
      const result = await this.$req.post('/jianxiang/api/1/albums/', {
        name: album.name,
        cover: album.cover,
        innerStyle: {
          id: album.innerStyle.id
        },
        tid: album.tid,
        aid: album.aid
      })
      this.$router.push('/albums/' + result.aid)
    },
    getDelegate(e, selector) {
      let target = e.target
      while (target) {
        if (!target) {
          return null
        }
        if (target.matches(selector)) {
          return target
        }
        target = target.parentElement
      }
    },

    uploadImages(uploadOnly) {
      return this.storage.upload(20).then(results => {
        if (!results.length) {
          return
        }
        return this.saveImages(results, uploadOnly)
      })
    },
    saveImages(results, uploadOnly = false) {
      const data = {
        images: results,
        uploadOnly
      }
      return this.$req.post(`/jianxiang/api/1/albums/${this.album.aid}/activities/`, data)
        .then(result => {
          this.imageCount += results.length
          return result
        })
    },
    navigate(offset) {
      let index = this.activeIndex
      if (index === 'cover') {
        index = -1
      }
      index += offset
      if (index > this.albumPages.length - 1) {
        index = this.albumPages.length - 1
      }
      if (index <= -1) {
        index = 'cover'
      }
      this.activeIndex = index
    },

    async addPage(index, name) {
      try {
        if (!name) {
          return
        }
        this.addingPage = true
        const pages = await this.sendOperation('insert_empty_page', {index, name})
        this.activeIndex = Math.max(0, Math.min(pages.length - 1, index))
        this.newlyAdded = index
      } finally {
        this.addingPage = false
      }
    },
    async deletePage(index) {
      const page = this.albumPages[index]
      if (this.albumPages.length <= 1) {
        return this.$alert.error('至少要保留一页哦')
      }
      if (page && page.layers.filter(i => i.content).length) {
        const confirmed = await this.$dialog.confirm({
          title: '删除页面',
          content: '删除后文字将会丢失，图片将会保留在图库待下次使用。'
        })
        if (!confirmed) {
          return
        }
      }
      if (index === this.newlyAdded) {
        this.newlyAdded = null
      }
      this.albumPages.splice(index, 1)
      this.sendOperation('delete_page', {index})
      if (this.activeIndex !== null) {
        this.activeIndex = Math.min(this.activeIndex, this.albumPages.length - 1)
      }
    },
    async syncNotePages() {
      const cover = this.album.cover
      const innerStyleId = cover.codeName.split('.')[0]
      this.album.innerStyle.id = innerStyleId
      await this.sendOperation('change_inner_style', {innerStyleId})
    },
    swapPage({oldIndex, newIndex}) {
      return this.sendOperation('relocation', {source: oldIndex, target: newIndex})
    },

    async saveAlbum(data) {
      const result = await this.$req.put(`/jianxiang/api/1/albums/${this.album.aid}/`, data)
      Object.assign(this.album, extendAlbum(result))
    },
    syncPage(indexes) {
      if (!Array.isArray(indexes)) {
        indexes = [indexes]
      }
      const pages = indexes.map(index => {
        const page = cloneDeep(this.albumPages[index])
        page.index = index
        page.layers = page.layers.map(layer => {
          layer = pick(layer, ['id', 'content', 'x', 'y', 'type', 'text', 'scale', 'rotate'])
          if (layer.type === 'image') {
            if (layer.content) {
              layer.content = layer.content.split('!')[0]
            } else {
              // 清空图层注释及 URL
              layer.content = null
              layer.text = ''
            }
          } else {
            delete layer.x
            delete layer.y
            delete layer.text
          }
          return layer
        })
        return page
      })
      return this.sendOperation('set_pages', {pages})
    },
    async sendOperation(action, params) {
      const pages = await this.$req.post(`/jianxiang/api/1/albums/${this.album.aid}/operation/`, {
        action,
        ...params
      })
      if (!isEmpty(pages)) {
        this.albumPages = this.$ajax.extendAlbumPages(pages)
      }
      if (['change_inner_style', 'delete_page', 'change_name'].includes(action)) {
        this.updateImages()
      }
      return pages
    },

    hasText(index) {
      return !!chain(this.albumPages).get([index, 'layers']).find(l => l.type === 'text').value()
    },
    hasImage(index) {
      return !!chain(this.albumPages)
        .get([index, 'layers'])
        .find(l => l.type === 'image' && l.content)
        .value()
    },

    onKeyUp(e) {
      if (this.menu) {
        return
      }
      if (/INPUT|TEXTAREA/.test(e?.target?.tagName)) {
        return
      }
      if (e.which === 37 || e.which === 33) {
        // 上一页
        // <-
        // PageUp
        this.navigate(-1)
      }
      if (e.which === 39 || e.which === 34) {
        // 下一页
        // ->
        // PageDown
        this.navigate(1)
      }
      if (e.which === 36) {
        // Home
        // 第一页
        this.navigate(-1000)
      }
      if (e.which === 35) {
        // End
        // 最后一页
        this.navigate(1000)
      }
    }
  },
  beforeDestroy() {
    this.storage.destroy()
    this.$el.removeEventListener('contextmenu', this.onContextMenu)
    document.removeEventListener('keyup', this.onKeyUp)
  }
}
</script>

<style>
body[data-page="album"] main {
  padding-bottom: 0;
}
</style>

<style lang="scss" scoped>
$sidebar-width: 180px;

.album {
  max-width: 100%;
  user-select: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  @include media-breakpoint-up(sm) {
    height: calc(#{100vh} - 57px);
    padding-left: $sidebar-width;
    padding-right: $sidebar-width;

    .album-navigation {
      width: $sidebar-width;
    }
  }

  .previewer {
    position: relative;
    flex-grow: 1;

    ::v-deep {
      [name="background"] {
        pointer-events: none;
      }
    }
  }

  .bottom.nav {
    position: relative;
  }

  &.editable {
    .previewer {
      ::v-deep .image:not([name^="pic"]) {
        pointer-events: none;
      }

      ::v-deep .image[name^="pic"] {
        &[data-default="true"] {
          &:before {
            font-size: 12px;
            content: '点击添加图片';
            text-align: center;
            color: #666;
            opacity: .9;
            position: absolute;
            background-color: #f2f2f2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            pointer-events: none;
          }
        }
      }

      ::v-deep .text[name^="text"] {
        &:not([style*="background-image:"]) {
          background-color: #f9f4e9;
          border: 1px solid rgba(0, 0, 0, .02);

          &:before {
            font-size: 12px;
            content: '✏️ 点击编辑文字';
            text-align: center;
            color: #999;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 8em;
            height: 1.5em;
            margin: auto;
            pointer-events: none;
          }
        }
      }

      &[data-tid="note"] {
        ::v-deep {
          .image, .text {
            &:before {
              display: none;
              visibility: hidden;
            }
          }
        }
      }
    }

    &[data-menu="pick#pic"] .previewer {
      ::v-deep .image[name="pic"] {
        &:before {
          white-space: pre-wrap;
          content: '再次点击取消';
          color: $primary;
        }
      }
    }

    &[data-menu="pick#pic2"] .previewer {
      ::v-deep .image[name="pic2"] {
        &:before {
          white-space: pre-wrap;
          content: '再次点击取消';
          color: $primary;
        }
      }
    }

    &[data-menu="pick#pic3"] .previewer {
      ::v-deep .image[name="pic3"] {
        &:before {
          white-space: pre-wrap;
          content: '再次点击取消';
          color: $primary;
        }
      }
    }

    &[data-menu="pick#pic4"] .previewer {
      ::v-deep .image[name="pic4"] {
        &:before {
          white-space: pre-wrap;
          content: '再次点击取消';
          color: $primary;
        }
      }
    }

    &[data-menu="pick#pic5"] .previewer {
      ::v-deep .image[name="pic5"] {
        &:before {
          white-space: pre-wrap;
          content: '再次点击取消';
          color: $primary;
        }
      }
    }

    &[data-menu="pick#pic6"] .previewer {
      ::v-deep .image[name="pic6"] {
        &:before {
          white-space: pre-wrap;
          content: '再次点击取消';
          color: $primary;
        }
      }
    }

    &[data-menu="pick#pic7"] .previewer {
      ::v-deep .image[name="pic7"] {
        &:before {
          white-space: pre-wrap;
          content: '再次点击取消';
          color: $primary;
        }
      }
    }

    &[data-menu="pick#pic8"] .previewer {
      ::v-deep .image[name="pic8"] {
        &:before {
          white-space: pre-wrap;
          content: '再次点击取消';
          color: $primary;
        }
      }
    }

    &[data-menu="pick#pic9"] .previewer {
      ::v-deep .image[name="pic9"] {
        &:before {
          white-space: pre-wrap;
          content: '再次点击取消';
          color: $primary;
        }
      }
    }
  }
}

::v-deep .tip {
  padding: 1rem;
  position: fixed;
  top: 1em;
  left: 1em;
  right: 1em;
  background-color: #333;
  color: #fff;
  border-radius: 100px;
  text-align: center;
  z-index: 55;
  margin-left: auto;
  margin-right: auto;
  max-width: 560px;
  box-shadow: $box-shadow;
  transition: .3s;
}

.dimmer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, .3);
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s;
}

.guide {
  max-width: 320px;
  text-align: center;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.album-menu-zone {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  border-top: 1px solid $hr-border-color;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 21;
  padding-bottom: 0;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;

  @include iPhoneX {
    padding-bottom: $safe-area-bottom;
  }

  @include media-breakpoint-up(sm) {
    width: 640px;
    z-index: 30;
    top: auto;
    margin: auto;
    border: 1px solid $hr-border-color;
    border-radius: 4px;

    ~ .dimmer {
      pointer-events: auto;
      visibility: visible;
      opacity: 1;
    }

    &.slide-up-leave-to ~ .dimmer {
      opacity: 0;
    }
  }

  &.album-full-menu {
    height: 100vh;
    max-height: 100vh;

    @include media-breakpoint-up(sm) {
      height: calc(#{100vh} - 56px);
    }
  }

  ::v-deep {
    .buttons {
      display: flex;
      flex-direction: row;

      @include media-breakpoint-up(sm) {
        .btn-block {
          margin-top: 0;
          margin-right: 5px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;

        .btn-block {
          margin-top: 8px;

          &:last-of-type {
            margin-top: 0;
          }
        }
      }
    }

    > .container, > header, > footer {
      padding: $grid-gutter-width / 2;
      width: 100%;
    }

    > header {
      padding-bottom: 0;
    }

    > .container {
      // 不能 flex: 1，在 ios < 10.3 有问题
      flex-grow: 1;

      &.scrollable {
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;

        ~ footer {
          border-top: 1px solid $hr-border-color;
        }
      }
    }
  }
}

.navigation {
  width: 100%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  padding-bottom: 0;
}

.album-footer-menu {
  position: relative;
  @include media-breakpoint-up(sm) {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: $sidebar-width;
    background-color: #fff;
    border-left: 1px solid $hr-border-color;
    padding: 10px;
  }

  .side-menu {
    position: relative;
    margin: 0;
  }
}
</style>
