<template>
  <div class="album-guide-zone album-menu-zone album-top-menu album-full-menu">
    <h2 class="page-title" v-if="isRoute">照片书使用说明</h2>
    <div class="container scrollable">
      <div class="guides">
        <div class="guide" v-for="item in guides" :key="item.title">
          <div class="title" @click="showGuide(item.title, $event)">
            {{item.title}}
            <span class="float-right">
              <fa icon="caret-up" v-if="active === item.title"/>
              <fa icon="caret-down" v-else/>
            </span>
          </div>
          <div class="content" v-if="active === item.title">
            <p v-html="item.desc"></p>
            <square class="pic" :src="item.pic" height="217%"/>
          </div>
        </div>
      </div>
    </div>
    <footer v-if="!isRoute">
      <b-btn block @click="back">返回</b-btn>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'albumGuideZone',
  data() {
    return {
      isRoute: this.$route.name === 'albumGuide',
      active: '',
      guides: [{
        title: '如何上传手机中的照片',
        desc: '点击红色<b>上传图片</b>按钮，选择图片后点击完成',
        pic: 'https://img.xinshu.me/upload/0105cab1f018a48206feb935fcbaec26'
      }, {
        title: '如何将图片填入作品',
        desc: '<b>长按</b>缓存区中的图片，<b>拖动</b>到页面中填图位置后<b>松手</b>即可',
        pic: 'https://img.xinshu.me/upload/21323c97b542a5c74bbf8879b6f9d0ba'
      }, {
        title: '如何对已填入的图片进行替换',
        desc: '<b>长按</b>缓存区中的图片，<b>拖动</b>到页面中已填入图片的位置后<b>松手</b>即可',
        pic: 'https://img.xinshu.me/upload/a23db80005e9b197db4151047aca2aff'
      }, {
        title: '如何交换页面中的图片',
        desc: '<b>长按</b>页面中已填入的图片，<b>拖动</b>到其他已填入图片位置后<b>松手</b>即可',
        pic: 'https://img.xinshu.me/upload/a23db80005e9b197db4151047aca2aff'
      }, {
        title: '如何卸下已填入的图片',
        desc: '<b>长按</b>页面中已填入的图片，<b>拖动</b>到缓存区后<b>松手</b>即可',
        pic: 'https://img.xinshu.me/upload/25ffa038ff5ffce2d13487ad13c3507d'
      }, {
        title: '如何删除缓存区的图片',
        desc: '<b>长按</b>缓存区中的图片，<b>拖动</b>到下方删除区域后<b>松手</b>即可',
        pic: 'https://img.xinshu.me/upload/77594aa96ad1a2609996c7669816086c'
      }, {
        title: '如何切换封面样式并自定义图片',
        desc: '<b>点击</b>页面中的封面，点击<b>样式</b>选项卡，点击中间不同的封面即可切换封面样式。点击下方<b>自定义图片</b>，即可上传自己的照片',
        pic: 'https://img.xinshu.me/upload/5cec20b29e2a1e372e6ad3881ece8dcf'
      }, {
        title: '如何调整封面图片',
        desc: '大部分封面都可以上传自己的照片作为封面图片，点击<b>样式</b>选项卡，点击<b>微调图片</b>',
        pic: 'https://img.xinshu.me/upload/1c4fab22d041f9fd88d7fdc86971819b'
      }, {
        title: '如何切换整本书的风格',
        desc: '点击下方<b>更换风格</b>选项，点击不同的风格图片即可切换。（已撰写文本后，切换内页风格可能会导致文本丢失，请谨慎操作）',
        pic: 'https://img.xinshu.me/upload/02c5e83256ae7a0fc337a5858b8d67e9'
      }, {
        title: '如何对图片进行调整',
        desc: '<b>点击</b>页面中的图片，可对图片进行旋转、缩放、位移的调整',
        pic: 'https://img.xinshu.me/upload/60ce43f54353d2eff897605bff37f463'
      }, {
        title: '如何为单张照片添加注释',
        desc: '<b>点击</b>每页下方的<b>添加注释</b>按钮，可对图片添加注释。注释显示在图片的右下角，异形样式（圆形菱形等）的照片注释可能被裁切',
        pic: 'https://img.xinshu.me/upload/9ad749cab20df76b3f0e9e9b7506a6d6'
      }, {
        title: '如何为整页内容撰写文本',
        desc: '<b>点击</b>每页下方的<b>撰写文本</b>按钮，可在页面中撰写文本。经典纪念册留空显示默认文本，文绘集留空则保持空白',
        pic: 'https://img.xinshu.me/upload/bc945fdaa7e24f7e74f3fa2e0843406a'
      }, {
        title: '如何添加页面',
        desc: '<b>点击</b>每页下方的<b>添加页面</b>按钮，会跳出<b>选择模板</b>界面，选择模板后，点击完成即可。也可以从<b>管理页面</b>进行操作哦',
        pic: 'https://img.xinshu.me/upload/9527a84da280e3211569b6dd66606fd6'
      }, {
        title: '如何删除页面',
        desc: '<b>点击</b>每页右上角的<b>圆形减号</b>按钮，即可删除页面。也可以从<b>管理页面</b>进行操作哦',
        pic: 'https://img.xinshu.me/upload/b6da361fdc27b30e9646395af78ac2d2'
      }, {
        title: '如何更换页面版式',
        desc: '<b>点击</b>每页下方的<b>更换版式</b>按钮，会跳出<b>选择模板</b>界面，选择模板后，点击完成即可。注意，如果多图版式切换至少图模式，图片将会进入缓存区',
        pic: 'https://img.xinshu.me/upload/2a326ee5205cdd3f38bee9c9ad52357e'
      }, {
        title: '如何调换页面位置',
        desc: '<b>点击</b>导航区<b>管理页面</b>选项，长按单个页面，拖放调整顺序',
        pic: 'https://img.xinshu.me/upload/3afa953e120a0975dbdad7e6d5f5842b'
      }],
      swiperOptions: {
        autoplay: {
          delay: 6000
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  methods: {
    showGuide(title, e) {
      this.active = this.active === title ? '' : title
      this.$nextTick(() => {
        e.target.scrollIntoView()
      })
    },
    back() {
      if (this.$route.name === 'albumGuide') {
        return this.$router.back()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .album-guide-zone.container {
    max-width: 640px;
  }

  .album-guide-zone.album-menu-zone {
    top: 0;
    z-index: 20;
    overflow: auto;

    .guide-image {
      max-width: 60%;
    }

    .guides {
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;
    }

    .guide {
      .title {
        padding: 1rem;
        cursor: pointer;
        border-bottom: 1px solid #eee;
      }

      .content {
        padding: 1rem;
        background-color: #f2f2f2;
        text-align: left;
      }

      p {
        margin-bottom: .5em;
      }

      .pic {
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .bottom {
      display: block;
      @include media-breakpoint-up(sm) {
        padding-left: #{calc((100% - 800px) / 2)};
        padding-right: #{calc((100% - 800px) / 2)};
      }
    }

    footer {
      padding: 1rem;
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
