<template>
  <div class="album-note-zone">
    <div class="actions">
      <b-btn @click="uploadNotePic" variant="success" :disabled="!!storage.uploading">
        <fa icon="spinner" spin v-if="storage.uploading"/>
        上传图片
      </b-btn>
      <div class="right">
        <b-btn @click="editTitle">修改作品名</b-btn>
      </div>
    </div>

    <b-modal title="修改标题" id="title" @ok="saveTitle">
      <b-form-group label="作品标题">
        <b-input v-model="title" placeholder="请输入作品标题"/>
      </b-form-group>
      <b-form-group label="作品副标题 / 作者">
        <b-input v-model="subtitle" placeholder="献给那些美好的回忆"/>
      </b-form-group>
    </b-modal>

    <move-area ref="moveArea" v-model="adjustingImage" v-if="adjustingImage">
      <div class="note" v-if="adjustingImage.text" slot="image">{{adjustingImage.text}}</div>
      <template v-slot:buttons="{cancel}">
        <b-row class="buttons" v-if="!addingText">
          <b-col>
            <b-btn @click="cancel" class="w-100">
              取消
            </b-btn>
          </b-col>
          <b-col>
            <b-btn @click="addingText = true" class="w-100">
              <fa fw :icon="adjustingImage.text ? 'edit' : 'font'"/>
              注释
            </b-btn>
          </b-col>
          <b-col>
            <b-btn @click="saveAdjustment" class="w-100" variant="primary">
              <fa fw icon="check"/>
              确认
            </b-btn>
          </b-col>
        </b-row>
        <b-input-group v-else>
          <b-input placeholder="在此处输入图片注释（最多 24 字）" maxlength="24" v-model="adjustingImage.text"
                   autofocus/>
          <b-btn @click="saveText" slot="append">
            <fa icon="spinner" spin v-if="savingText"/>
            <fa icon="check" v-else/>
          </b-btn>
        </b-input-group>
      </template>
    </move-area>
  </div>
</template>

<script>
import inherits from '@/mixins/inherits'
import Upload from '@/models/upload'
import { isEqual } from 'lodash'

export default {
  name: 'albumNoteZone',
  mixins: [inherits('album', 'albumPages', 'layers')],
  components: {
    MoveArea: require('@/components/MoveArea').default
  },
  data() {
    return {
      title: '',
      subtitle: '',
      addingText: false,
      savingText: false,
      adjustingImage: null,
      storage: new Upload(),
      previewZone: document.querySelector('.previewer')
    }
  },
  created() {
    this.previewZone.addEventListener('click', this.onImageClick)
  },
  beforeDestroy() {
    this.previewZone.removeEventListener('click', this.onImageClick)
  },
  methods: {
    onImageClick(e) {
      const t = this.getDelegate(e, '.image[name="pic"]')
      if (t && t.dataset.default === 'false') {
        this.initAdjusting(t)
      }
    },
    async initAdjusting(t) {
      if (!t) {
        return
      }
      const layer = this.layers.find(l => l.index === t.dataset.index)
      if (!layer) {
        throw new Error('Layer not found')
      }

      const src = layer.content
      const {w, h} = t.dataset
      this.adjustingImage = {
        id: layer.id,
        pageIndex: layer.pageIndex,
        src,
        text: layer.text,
        dw: layer.w,
        dh: layer.h,
        w,
        h,
        mode: parseInt(t.dataset?.mode),
        x: layer.x,
        y: layer.y,
        rotate: layer.rotate || 0,
        scale: layer.scale || '100%',
        layer,
        el: t
      }
    },
    async saveText() {
      try {
        const {text, pageIndex, layer} = this.adjustingImage
        layer.text = text || ''
        this.savingText = true
        const data = {
          index: pageIndex,
          pid: layer.id,
          text: text
        }
        await this.$parent.sendOperation('modify_image', data)
        this.addingText = false
      } finally {
        this.savingText = false
      }
    },
    saveAdjustment() {
      const {x, y, scale, rotate, pageIndex, layer, el} = this.adjustingImage
      const bgPos = x + ' ' + y

      this.adjustingImage = null
      this.operation = ''

      if (!layer) {
        return
      }

      if (isEqual([x, y, scale, rotate], [layer.x, layer.y, layer.scale, layer.rotate])) {
        return
      }

      layer.x = x
      layer.y = y
      layer.scale = scale
      layer.rotate = rotate

      this.$parent.syncPage(pageIndex)
      el.style.backgroundPosition = bgPos
    },

    getDelegate(e, selector) {
      let target = e.target
      while (target) {
        if (!target) {
          return null
        }
        if (target.matches(selector)) {
          return target
        }
        target = target.parentElement
      }
    },
    async uploadNotePic() {
      const result = await this.storage.upload(1)
      if (!result) {
        return
      }
      const url = `/jianxiang/api/1/albums/${this.album.aid}/activities/`
      const [image] = await this.$req.post(url, {images: [result]})
      this.albumPages.forEach(p => {
        const pic = p.layers.find(l => l.name === 'pic')
        pic.content = image.url
        pic.id = image.pid
      })
      await this.$parent.syncPage(this.albumPages.map(i => i.pageIndex))
    },
    editTitle() {
      this.title = this.album.title
      this.subtitle = this.album.cover.subtitle
      this.$bvModal.show('title')
    },
    async saveTitle() {
      const result = await this.$req.put(`/jianxiang/api/1/albums/${this.album.aid}/`, {
        name: this.title,
        cover: {...this.album.cover, subtitle: this.subtitle}
      })
      Object.assign(this.album, result)
      this.$alert.success('作品名称已修改')
    }
  }
}
</script>

<style lang="scss" scoped>
.actions {
  padding: 8px;
  border-top: 1px solid $hr-border-color;
  @include clearfix();

  @include media-breakpoint-up(sm) {
    padding: 0;
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    border-top: 0;
    border-bottom: 1px solid $hr-border-color;

    &:before {
      display: block;
      margin-bottom: .5em;
      content: '— 当前页操作 —';
      text-align: center;
      color: $text-muted;
    }

    .btn {
      width: 100%;
      margin-bottom: .5rem;
    }
  }

  .right {
    @include media-breakpoint-down(sm) {
      float: right;
    }
  }
}
</style>
